
import { Component, Vue } from 'vue-property-decorator';
import TopToolbar from './components/TopToolbar.vue';
import BottomNav from './components/BottomNav.vue';
import ManageUserDialog from "@/views/user/ManageUserDialog.vue";
import Dashboard from "@/views/user/components/Dashboard.vue";
import { getModule } from "vuex-module-decorators";
import store, { siteStore, userStore } from "@/store";
import User from "@/store/modules/user";
import QrScanListDialog from "@/views/qrscan/QrScanListDialog.vue";
import Site from "@/store/modules/site";
import { ManageUserAction } from "@/views/user/type/ManageUserAction";
import SelectSiteDialog from "@/views/site/SelectSiteDialog.vue";
import { httpClient } from "@/api/httpClient";
import { timeUtils } from "@/lib/timeUtils";
import { tokenUtils } from "@/lib/tokenUtils";
import { brandApi } from './api/service/brandApi';

@Component({
  components: {
    ManageUserDialog,
    TopToolbar,
    BottomNav,
    QrScanListDialog,
    Dashboard
  }
})
export default class AppMain extends Vue {
  private shouldShowQrScanDialog = false;
  private shouldShowSelectSiteDialog = false;
  private shouldShowDashboardDialog = false;

  private selectedUserAction: ManageUserAction | null = null;

  private nowInSeconds = timeUtils.nowSeconds();

  private documentHidden = false;

  // manageUserRefreshWatch = new Date();

  timerId: number | null = null;

  mounted(): void {
    console.log("app main mounted");
    this.timerId = setInterval(this.refreshToken, 600000);
    // document.addEventListener('visibilitychange', () => {
    //   if (this.documentHidden && !document.hidden) {
    //     console.log("document appeared");
    //     this.onReloadPage();
    //   }
    //
    //   this.documentHidden = document.hidden;
    // }, false);
    this.checkBrandInfo()
  }

  get shouldShowManageUserDialog(): boolean {
    return this.manageUserAction != null;
  }

  get shouldShowDashboard(): boolean {
    return this.manageUserAction != null;
  }

  get manageUserAction(): ManageUserAction | null {
    if (this.shouldLoginAgain) {
      return ManageUserAction.Login;
    } else {
      return this.selectedUserAction;
    }
  }

  get shouldLoginAgain(): boolean {
    return siteStore.siteName != null &&
      (tokenUtils.tokenExpiredAtTime(userStore.refreshExpiresAt, this.nowInSeconds) || userStore.shouldSignInAgain);
  }

  get manageUserDialog(): Vue.Component | null {
    if (this.shouldShowManageUserDialog) {
      return ManageUserDialog;
    } else {
      return null;
    }
  }

  get dashboardDialog(): Vue.Component | null {
    return this.shouldShowDashboardDialog ? Dashboard : null;
  }

  get qrScanListDialog(): Vue.Component | null {
    return this.shouldShowQrScanDialog ? QrScanListDialog : null;
  }

  get selectSiteDialog(): Vue.Component | null {
    return this.shouldShowSelectSiteDialog ? SelectSiteDialog : null;
  }

  private async checkBrandInfo(): Promise<void> {
    console.log('checkBrandInfo');

    try {
      const response = await brandApi.queryBrandInfo();
      const brandInfo = response.data;

      if (brandInfo) {
        siteStore.updateBrandName(brandInfo.basicInfo?.brandName);
        siteStore.updateLogoUrl(brandInfo?.basicInfo?.logoUrl);
      }
    } catch (e) {
      const error = e as Error;
      alert(error.message);
      this.shouldShowSelectSiteDialog = true;
    }

    // brandApi.queryBrandInfo()
    //     .then(apiResponse => {
    //       siteStore.updateBrandName(apiResponse.data?.basicInfo?.brandName);
    //       siteStore.updateLogoUrl(apiResponse.data?.basicInfo?.logoUrl);
    //     })
    //     .catch(errorMessage => {
    //       alert(errorMessage);
    //     })
  }

  private onClickDashboard(): void {
    //this.$router.push('/' + siteStore.siteName + '/user-service')
    this.$router.push({ name: 'use-service', params: { site_name: `${this.$route.params.site_name}` } }).catch(() => { console.log() })
  }

  private onClickBookingCalender(): void {
    //this.$router.push('/' + siteStore.siteName + '/user-service')
    this.$router.push('/' + siteStore.siteName + '/booking/booking-calendar').catch(() => { console.log() })
  }


  private onClickOpenQrScan(): void {
    console.log('onClickQrScan');
    this.shouldShowQrScanDialog = true;
  }

  private onCloseQrScan(): void {
    console.log('onCloseQrScan');
    this.shouldShowQrScanDialog = false;
  }

  private onClickEditProfile(): void {
    //this.selectedUserAction = ManageUserAction.EditProfile;
    this.$router.push('/' + siteStore.siteName + '/use-service/edit-profile').catch(() => { console.log() })
  }

  private onClickChangePassword(): void {
    //this.selectedUserAction = ManageUserAction.ChangePassword;
    this.$router.push('/' + siteStore.siteName + '/use-service/change-password').catch(() => { console.log() })
  }

  private onClickLogout(): void {
    userStore.clearAll();
    userStore.updateShouldSignInAgain(true);
  }

  private onClickChangeBrand(): void {
    this.shouldShowSelectSiteDialog = true;
  }

  private onReloadPage(): void {
    console.log('reload')
    window.location.reload();
  }

  private onManageUserDone(): void {
    this.selectedUserAction = null;
  }

  private onClickSelectSite(): void {
    this.shouldShowSelectSiteDialog = true;
  }

  private loginComplete(): void {
    console.log('app main - login complete')
  }

  private onBrandChangeCancelled(): void {
    this.shouldShowSelectSiteDialog = false;
  }

  private onBrandSelected(siteName: string | null): void {
    console.log('onBrandSelected');
    console.log(siteName)
    if (siteName != null) {
      this.shouldShowSelectSiteDialog = false;
      this.selectedUserAction = ManageUserAction.Login;
      // this.manageUserRefreshWatch = new Date();
    }
  }

  private refreshToken(): void {
    this.nowInSeconds = timeUtils.nowSeconds();

    httpClient.refreshTokenAct()
      .then(token => {
        console.log("token refreshed");
      })
      .catch(message => {
        console.log(message);
      });
  }

  /* setBackgroundColor(): string {
    if () {
      return 'background-color: #ffffff !important;'
    } else {
      return 'background-color: var(--background) !important;'
    }
  } */
}

