
import { Component, Emit, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { couponUtils } from "@/lib/couponUtils";
import { CouponProps } from "@/components/coupon/type/CouponProps";
import { dateUtils } from "@/lib/dateUtils";
import { numberUtils } from "@/lib/numberUtils";
import { phoneUtils } from "@/lib/phoneUtils";

@Component
export default class CouponRow extends Vue {
  @Prop() readonly coupon!: CouponProps;
  @Prop() readonly selectedCouponList!: CouponProps[];


  showMenu = false;
  shown = false;
  menuItems: string[] = [];
  readonly emptyMenuItems: string[] = [];
  readonly availableMenuItems = ['쿠폰사용처리', '사용처리취소'];
  selectItem = false;

  mounted() {
    console.log(this.coupon);
  }

  @Watch('selectedCouponList')
  resetSelected(): void {
    if (this.selectedCouponList.length == 0)
      this.selectItem = false
  }

  get couponStatusCaption(): string {
    return couponUtils.couponStatusCaption(this.coupon);
  }

  get goodsTypeCaption(): string {
    return couponUtils.goodsTypeCaption(this.coupon.goodsType);
  }

  get isAvailableCoupon(): boolean {
    return couponUtils.isAvailableCoupon(this.coupon.itemStatus);
  }

  get canUse(): boolean {
    return couponUtils.canUse(this.coupon);
  }

  get inAvailablePeriod(): boolean {
    return couponUtils.inAvailablePeriod(this.coupon);
  }

  get isConsumedCoupon(): boolean {
    return couponUtils.isConsumedCoupon(this.coupon.itemStatus);
  }

  get hasUseTransaction(): boolean {
    return this.coupon.consumedDate != undefined;
  }

  get isMobileCoupon(): boolean {
    return couponUtils.isMobileCoupon(this.coupon.goodsType);
  }

  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.coupon.goodsType);
  }

  get isBookingCoupon(): boolean {
    return couponUtils.isBookingCoupon(this.coupon.goodsType);
  }

  get consumedDate(): string {
    return dateUtils.formatDate(this.coupon.consumedDate);
  }

  get usableAmount(): string {
    return numberUtils.formatNumber(this.coupon.usableAmount);
  }

  get hasUsableAmount(): boolean {
    return this.coupon.usableAmount ? parseInt(this.coupon.usableAmount) > 0 : false;
  }

  get receiverPhone(): string {
    return phoneUtils.formatPhoneNumber(this.coupon.receiverPhone);
  }

  get backgroundColor(): string {
    if (this.isAvailableCoupon) {
      return "#FFFFFF"
    } else {
      // return "#FFF8E1"
      return 'var(--background-02)'
    }
  }

  menuBackgroundColor(index: number): string {
    if (index == 0) {
      // return "primary";
      return '#F1F9FF';
    } else {
      // return "error";
      return '#FFF5F5';
    }
  }

  menuTextColor(index: number): string {
    if (index == 0) {
      // return "primary";
      return 'color: var(--primary-blue);';
    } else {
      // return "error";
      return 'color: var(--primary-main);';
    }
  }

  onClickCoupon(): void {
    if (this.isAvailableCoupon && this.hasUseTransaction) {
      this.menuItems = this.availableMenuItems;
    } else {
      this.menuItems = this.emptyMenuItems;

      if (this.isAvailableCoupon) {
        this.useCoupon();
      } else {
        this.cancelUseCoupon();
      }
    }

    console.log("onClickCoupon");
  }

  onMenuSelected(index: number): void {
    if (index === 0) {
      this.useCoupon();
    } else {
      this.cancelUseCoupon();
    }
  }

  maskingReceiverName(receiverName: string): string {
    let fullString = receiverName
    let maskingString = ''

    for (let i = 0; i < fullString.length; i++) {
      if (i == 0 || i == fullString.length - 1) {
        maskingString += fullString[i]
      } else {
        maskingString += '*'
      }
    }
    return maskingString;
  }

  maskingReceiverPhone(receiverPhone: string): string {
    let fullString = receiverPhone
    let maskingString = ''
    let maskingFullString = ''
    for (let i = 0; i < fullString.split('-').length; i++) {
      if (i == 1) {
        for (let j = 0; j < fullString.split('-')[i].length; j++) {
          if (j < 2) {
            maskingString += '*'
          } else {
            maskingString += fullString.split('-')[i][j]
          }
        }
        maskingFullString += maskingString
      } else {
        maskingFullString += fullString.split('-')[i]
      }

      if (i < fullString.split('-').length - 1) {
        maskingFullString += '-'
      }
    }


    return maskingFullString
  }

  showCheckbox(): boolean {
    const today = new Date()
    const availableTo = new Date(this.coupon.availableTo).getTime()

    if (availableTo > today.setDate(today.getDate() - 5) && this.coupon.itemStatus == 'Available') {
      if (this.selectedCouponList.length < 1) {
        return true
      }
      else {
        if (this.selectedCouponList[0].goodsType == this.coupon.goodsType) {
          return true
        } else {
          return false
        }
      }
    }
    else { return false }
  }

  private checkboxChangeState() {
    this.selectItem = !this.selectItem;
    this.checkboxCoupon()
  }

  @Emit()
  useCoupon(): CouponProps {
    return this.coupon;
  }

  @Emit()
  cancelUseCoupon(): CouponProps {
    return this.coupon;
  }

  @Emit()
  checkboxCoupon(): CouponProps {
    return this.coupon;
  }
}
