export enum BoardListKeywordTypeEnum {
  NoKeyword = "NoKeyword",
  TitleContaining = "TitleContaining",
  RegisteredBy = "RegisteredBy",
}

export interface NoticeAdminContentDto {
  noticeNo: number;
  title: string;
  registeredDate: string;
  registeredBy: string;
  published: boolean;
  useFlag: boolean;
  imageUrl: string;
  importantFlag: boolean;
}

export interface NoticeAdminSortDto {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface NoticeAdminPageableDto {
  sort: NoticeAdminSortDto;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface NoticeAdminBoardPageDto {
  content: NoticeAdminContentDto[];
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  first: boolean;
  last: boolean;
  empty: boolean;
  pageable: NoticeAdminPageableDto;
}

export interface NoticeAdminBoardDetailDto {
  noticeNo: number;
  title: string;
  contents: string;
  registeredDate: string;
  registeredBy: string;
  useFlag: boolean;
  imageUrl: string;
  published: boolean;
  importantFlag: boolean;
}
