
import { bookingApi } from "@/api/service/bookingApi";
import { brandApi } from "@/api/service/brandApi";
import { bookingStatusDatesDto, bookingStatusMonthlyDto, bookingStatusWeeklyDto } from "@/api/service/dto/brand/bookingCalendarDto";
import { BookingDateTypeEnum, BookingListKeywordTypeEnum } from "@/api/service/dto/order/bookingListDto";
import { Component, Vue, Watch } from "vue-property-decorator";
import BookingCalendarMonthDialog from "@/views/booking/components/BookingCalendarMonthDialog.vue";
import BookingListDialog from "@/views/booking/components/BookingListDialog.vue";
import { GoToOptions } from "vuetify/types/services/goto";
import { CouponTransactionPropsUtil } from "@/components/coupon/type/CouponTransactionProps";

@Component({})
export default class BookingCalendar extends Vue {
  types = [{ index: 0, value: 'month', label: '월간' }, { index: 1, value: 'week', label: '주간' }]
  tabIndex = null
  type = 'month'

  private daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
  private storeCode = '';

  private shouldShowBookingCalendarMonthDialog = false;
  private shouldShowBookingListDialog = false;

  get bookingCalendarMonthDialog(): Vue.Component | null {
    return this.shouldShowBookingCalendarMonthDialog ? BookingCalendarMonthDialog : null;
  }

  get bookingListDialog(): Vue.Component | null {
    return this.shouldShowBookingListDialog ? BookingListDialog : null;
  }

  mounted(): void {
    this.getStoreCode()
    this.selectedDate = this.setDateStringFormat(this.today)
    this.setBookingStatusMonthly()
  }

  updated(): void {
    if (this.tabIndex == 1) {
      this.weeklyScrollTo()
    }
  }


  today = new Date();
  currentMonth = this.today.getMonth();
  currentYear = this.today.getFullYear();
  startDayOfWeek = 0;
  endDayOfWeek = 0;
  selectedDate = '';
  dates: bookingStatusDatesDto[] = []
  monthly: bookingStatusMonthlyDto | null = null;
  weekly: bookingStatusWeeklyDto | null = null;

  scrollTarget = 0
  scrollOptions: GoToOptions = {
    duration: 300,
    offset: 0,
    easing: 'easeInOutCubic'
  };

  setDateStringFormat(date: Date | string): string {
    if (typeof date !== 'string') {
      if (date.getMonth() + 1 < 10) {
        if (date.getDate() < 10) {
          return `${date.getFullYear()}-0${date.getMonth() + 1}-0${date.getDate()}`
        } else {
          return `${date.getFullYear()}-0${date.getMonth() + 1}-${date.getDate()}`
        }
      } else {
        if (date.getDate() < 10) {
          return `${date.getFullYear()}-${date.getMonth() + 1}-0${date.getDate()}`
        } else {
          return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        }
      }
    }
    else { return date }
  }

  tdHeight = 0

  setTdHeight(): void {
    const element = document.querySelectorAll('hour-th-00')[0]
    console.log(element?.getBoundingClientRect().height)
    this.tdHeight = element?.getBoundingClientRect().height

  }

  renderCalendar(): void {
    const firstDayOfMonth = new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]) - 1, 1)
    this.startDayOfWeek = firstDayOfMonth.getDay()
    const lastDayOfMonth = new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]), 0)
    this.endDayOfWeek = lastDayOfMonth.getDay()
  }

  onClickMonth(type: string): void {
    if (type == 'prev') {
      if (this.type == 'month') {
        this.selectedDate = this.setDateStringFormat(new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]) - 2, 1))
        this.setBookingStatusMonthly()
      } else {
        this.selectedDate = this.setDateStringFormat(new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]) - 1, Number(this.selectedDate.split('-')[2]) - 7))
        this.setBookingStatusWeekly()
      }
    } else {
      if (this.type == 'month') {
        this.selectedDate = this.setDateStringFormat(new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]), 1))
        this.setBookingStatusMonthly()
      } else {
        this.selectedDate = this.setDateStringFormat(new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]) - 1, Number(this.selectedDate.split('-')[2]) + 7))
        this.setBookingStatusWeekly()
      }
    }
  }

  onClickToday(): void {
    this.selectedDate = this.setDateStringFormat(this.today)
    if (this.type == 'week') {
      this.setBookingStatusWeekly()
    } else {
      this.setBookingStatusMonthly()
    }
  }


  private async getStoreCode() {
    const response = await brandApi.queryStoreInfo()
    this.storeCode = response.data?.storeCode ?? '';
  }

  private async setReserveSummary(start: string, end: string): Promise<void> {
    const response = await bookingApi.bookingReserveSummary(BookingDateTypeEnum.VisitDate, start, end, this.storeCode, BookingListKeywordTypeEnum.NoKeyword)

    if (response.code == 'OK' && response.data) {
      console.log(response.data)
    }
  }

  private async setBookingStatusMonthly() {

    this.renderCalendar()
    try {
      const response = await brandApi.bookingStatusMonthly(`${this.selectedDate.split('-')[0]}-${this.selectedDate.split('-')[1]}`)
      this.monthly = response.data ?? null;

      if (this.monthly)
        this.setReserveSummary(this.monthly.fromDate, this.monthly.toDate)
    } catch (e) {
      const error = e as Error;
      console.log(error)
    }
  }

  private async setBookingStatusWeekly() {

    try {
      const response = await brandApi.bookingStatusWeekly(this.selectedDate)
      this.weekly = response.data ?? null;
    } catch (e) {
      const error = e as Error;
      console.log(error)
    }
  }


  @Watch('weekly')
  async weeklyScrollTo(): Promise<void> {
    if (this.tabIndex == 1) {
      let nowHours = new Date().getHours().toString()
      let nowMin = new Date().getMinutes()

      if (Number(nowHours) < 10) {
        nowHours = '0' + nowHours
      }

      if (nowMin >= 30) {
        const hourDom = document.querySelectorAll('#hour-th-30')

        if (hourDom.length !== 0) {
          hourDom.forEach((item) => {
            if (item.innerHTML.split(':')[0] == nowHours) {
              this.$vuetify.goTo(item as HTMLElement, this.scrollOptions)

              return true
            }

          })
        }

      }
      else {
        const hourDom = document.querySelectorAll('#hour-th-00')
        if (hourDom.length !== 0) {
          hourDom.forEach((item) => {
            this.tdHeight = item.getBoundingClientRect().height - 24
            if (item.innerHTML.split(':')[0] == nowHours) {
              this.$vuetify.goTo(item as HTMLElement, this.scrollOptions)

              return true
            }

          })
        }
      }

      console.log(this.tdHeight)
      /* console.log(this.scrollTarget)
      this.$vuetify.goTo(Number(this.scrollTarget), this.scrollOptions) */
    }
  }



  private onClickDate(date: bookingStatusDatesDto) {
    this.selectedDate = this.setDateStringFormat(date.date)
  }



  clickType(item: { index: number; value: string; label: string; }): void {
    this.type = item.value
    if (this.type == 'month') {
      this.setBookingStatusMonthly()
    } else {
      this.setBookingStatusWeekly()
    }
  }

  setDateColor(date: number): string {
    if (date == this.today.getDate() && this.selectedDate.split('-')[2] == this.today.getDate().toString()) {
      return 'color: white;'
    } else {
      return 'color: #252525;'
    }
  }

  setCircleColor(date: number): string {
    if (date == this.today.getDate() && this.selectedDate.split('-')[2] == this.today.getDate().toString()) {
      return 'var(--primary-black)'
    } else {
      return 'white'
    }
  }

  setBgColor(date: number): string {
    if (date == this.today.getDate() && this.selectedDate.split('-')[2] == this.today.getDate().toString()) {
      return 'background-color: #FFF6DE;'
    } else {
      return 'background-color:white;'
    }
  }

  setChipColor(dateStatus: boolean): string {
    if (!dateStatus) {
      return 'color: var(--primary-white);'
    } else {
      return 'color: var(--primary-black);'
    }
  }

  setSlotChipColor(dateStatus: boolean, slotStatus: boolean): string {
    if (!dateStatus && !slotStatus) {
      return 'color: var(--primary-main); border: 0.5px solid var(--primary-main); background-color: #FFF5F5;'
    } else {
      return 'color: #939393; border: 0.5px solid #939393; background-color: #EFEFEF;'
    }
  }

  private async onClickStatusChip(selectItem: any) {
    if (selectItem.bookingPaused) {
      await brandApi.bookingResume(selectItem.date)

    } else {
      await brandApi.bookingStop(selectItem.date)
    }
    this.setBookingStatusMonthly()
  }

  private async onClickSlotStatusChip(date: string, selectItem: any, ahalf: boolean) {
    if (!ahalf) {
      if (selectItem.min00.bookingPaused) {
        await brandApi.bookingSlotResume(date, selectItem.hour, ahalf)
      } else {
        await brandApi.bookingSlotStop(date, selectItem.hour, ahalf)
      }
    } else {

      if (selectItem.min30.bookingPaused) {
        await brandApi.bookingSlotResume(date, selectItem.hour, ahalf)
      } else {
        await brandApi.bookingSlotStop(date, selectItem.hour, ahalf)
      }
    }
    this.setBookingStatusWeekly()
  }

  setWeeklyHeadFormat(date: string): string {
    const setDate = new Date(date)

    return `${setDate.getMonth() + 1}/${setDate.getDate()} ${this.daysOfWeek[setDate.getDay()]}`
  }

  selectYearAndMonth(date: string): void {
    this.selectedDate = date
    if (this.type == 'month') {
      this.setBookingStatusMonthly()
    } else {
      this.setBookingStatusWeekly()
    }
  }

  onClickShowBookingList(date: bookingStatusDatesDto): void {
    this.onClickDate(date)
    this.shouldShowBookingListDialog = true
  }
}
