
import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";
import CouponRow from "@/components/coupon/CouponRow.vue";
import { couponUtils } from "@/lib/couponUtils";
import { CouponProps } from "@/components/coupon/type/CouponProps";
import CouponInfo from "@/components/coupon/CouponInfo.vue";
import { numberUtils } from "@/lib/numberUtils";
import { dateUtils } from "@/lib/dateUtils";
import { couponApi } from "@/api/service/couponApi";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import { CouponTransactionProps } from "@/components/coupon/type/CouponTransactionProps";
import { LocalDate } from "js-joda";

@Component({
  components: { CouponInfo, CouponRow, MessageSnackbar }
})
export default class CancelUseCouponDialog extends Vue {
  @Prop() readonly coupon!: CouponProps;
  @Prop() transactions!: CouponTransactionProps[];
  @PropSync('showDialog', { type: Boolean }) syncShowDialog!: boolean;


  private selectedTransactionNo: string | null = null;

  // private errorMessage: string | null = null;
  errorMessage: string | null = null;
  showErrorMessage = false;
  readonly errorMessageType = SnackbarMessageType.Error;

  get usedTransactions(): CouponTransactionProps[] {
    return this.transactions;
  }

  get couponTypeName(): string {
    return couponUtils.goodsTypeCaption(this.coupon.goodsType);
  }

  get couponProps(): CouponProps {
    return this.coupon as CouponProps;
  }

  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.coupon.goodsType);
  }

  private get hasCancellableCoupon(): boolean {
    return this.transactions.some(this.isCancellable);
  }

  clearErrorMessage(): void {
    this.errorMessage = null;
  }

  accept(): void {
    this.cancelUseMobileCoupon();
  }

  private formattedMoney(money: string): string {
    return numberUtils.formatNumber(money);
  }

  private formattedDate(dateStr: string): string {
    return dateUtils.formatDate(dateStr);
  }

  private formattedTime(dateStr: string): string {
    return dateUtils.formatTime(dateStr);
  }

  private isCancellable(transaction: CouponTransactionProps): boolean {
    const useDate = dateUtils.toLocalDateFromISOStr(transaction.useDate);
    const today = LocalDate.now();

    const dateDifference = dateUtils.dateDifferenceBetween(useDate, today);
    if (dateDifference > 5) {
      return false;
    }

    const monthDifference = dateUtils.monthDifferenceBetween(useDate, today);
    if (monthDifference > 0) {
      return false;
    }

    if (couponUtils.isMoneyCoupon(transaction.goodsType)) {
      if (dateDifference > 0) {
        return false;
      }
    }

    return true;
  }

  private closeDialog(): void {
    this.syncShowDialog = false;
  }

  @Emit()
  useCancelled(): void {
    this.closeDialog();
  }

  private async cancelUseMobileCoupon(): Promise<void> {
    if (!this.selectedTransactionNo) {
      this.toastErrorMessage("취소할 트랜잭션을 선택하세요.");
      return;
    }

    try {
      console.log(this.coupon)
      await couponApi.cancelUseTransaction(this.coupon.itemNo, this.selectedTransactionNo);
      this.useCancelled();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // couponApi.cancelUseTransaction(this.coupon.itemNo, this.selectedTransactionNo)
    //     .then(apiResponse => {
    //       this.useCancelled();
    //     })
    //     .catch(message => {
    //       this.toastErrorMessage(message);
    //     });
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
}
