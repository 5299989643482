
import { bookingApi } from "@/api/service/bookingApi";
import { Component, Vue } from "vue-property-decorator";
import { dashboardDto, reservationStatusDto } from "@/api/service/dto/order/bookingListDto";
import SalesDetailDialog from "./SalesDetailDialog.vue";
import { siteStore } from "@/store";
import { BoardListKeywordTypeEnum, NoticeAdminBoardPageDto } from "@/api/service/dto/order/boardListDto";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.css";


@Component({
  components: {
    Swiper, SwiperSlide
  }
})

export default class Dashboard extends Vue {
  private saleDetails: dashboardDto | null = null;
  private reserveSummary: reservationStatusDto | null = null;
  private noticeAdminBoard: NoticeAdminBoardPageDto | null = null;

  private shouldShowSalesDetail = false;

  private keywordType = BoardListKeywordTypeEnum.NoKeyword;

  siteName = siteStore.siteName

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 15,//42,
    preloadImages: true,
    observer: true,
    observeParents: true,
  }

  mounted(): void {
    this.setSalesDetails()
    this.setReserveSummary()
    this.setNoticeAdminBoard()
  }

  get salesDetailDialog(): Vue.Component | null {
    return this.shouldShowSalesDetail ? SalesDetailDialog : null;
  }


  setSaleDetailsDateFormat(date: string): string {
    let salesDate = new Date(date)
    let setDate = `${salesDate.getMonth() + 1}월 ${salesDate.getDate()}일`

    return setDate
  }

  setPriceFormat(price: string): string {
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'
  }

  private async setSalesDetails(): Promise<void> {
    try {
      const response = await bookingApi.dashboard()
      this.saleDetails = response.data ?? null;
    } catch (e) {
      const error = e as Error;
      console.log(error)
    }
  }

  private async setReserveSummary(): Promise<void> {
    try {
      const response = await bookingApi.dashboardBookingReserveSummary()
      this.reserveSummary = response.data ?? null;
    } catch (e) {
      const error = e as Error;
      console.log(error)
    }
  }

  private async setNoticeAdminBoard(): Promise<void> {
    try {
      const response = await bookingApi.noticeAdminBoardPage(this.keywordType, null, null, 0, 3)
      this.noticeAdminBoard = response.data
    } catch (e) {
      const error = e as Error;
      console.log(error)
    }
  }

  private onClickSalesStatus(): void {
    this.$router.push(`/${siteStore.siteName}/sales/sales-status`)

  }
  private onClickReserveSummary(): void {
    this.$router.push(`/${siteStore.siteName}/booking/approve-booking`)
  }

  private onClickReserveBookingSummary(): void {
    this.$router.push(`/${siteStore.siteName}/booking/visit-store`)
  }

  private onClickNoticeItem(notice): void {
    this.$router.push('/' + siteStore.siteName + '/notice/notice-list/' + notice.noticeNo)
  }
}
