import Vue from "vue";
import Router from "vue-router";
import store, { siteStore } from "../store";

import Dashboard from "@/views/user/components/Dashboard.vue";
import NoticeIndex from "@/views/user/notice/NoticeIndex.vue";
import NoticeList from "@/views/user/notice/NoticeList.vue";
import NoticeInfo from "@/views/user/notice/NoticeInfo.vue";
import SalesIndex from "@/views/user/sales/SalesIndex.vue";
import SalesStatus from "@/views/user/sales/SalesStatus.vue";
import CouponList from "@/views/coupon/CouponList.vue";
import BookingList from "@/views/booking/visit/BookingList.vue";
import BookingRequestList from "@/views/booking/approve/BookingRequestList.vue";
import BookingCalendar from "@/views/booking/calender/BookingCalendar.vue";
import { getModule } from "vuex-module-decorators";
import Site from "@/store/modules/site";
import BookingIndex from "@/views/booking/BookingIndex.vue";
import AppMain from "@/AppMain.vue";
import SelectSite from "@/views/site/SelectSite.vue";
import SelectSiteDialog from "@/views/site/SelectSiteDialog.vue";
import EditProfile from "@/views/user/components/EditProfile.vue";
import ChangePassword from "@/views/user/components/ChangePassword.vue";
import UserIndex from "@/views/user/UserIndex.vue";

Vue.use(Router);

class RouteMeta {
  title: string;

  constructor({ title }: { title: string }) {
    this.title = title;
  }
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: SelectSiteDialog,
      meta: new RouteMeta({ title: "브랜드 선택" }),
    },
    {
      path: "/:site_name",
      name: "main",
      component: AppMain,
      redirect: "/:site_name/use-service",
      children: [
        {
          path: "/:site_name/use-service",
          name: "use-service",
          component: UserIndex,
          redirect: "/:site_name/use-service",
          meta: new RouteMeta({ title: "대시보드" }),
          children: [
            {
              path: "/:site_name/use-service",
              name: "dashboard",
              component: Dashboard,
              meta: new RouteMeta({ title: "대시보드" }),
            },
            {
              path: "/:site_name/use-service/use-coupon",
              name: "use-coupon",
              component: CouponList,
              meta: new RouteMeta({ title: "쿠폰사용처리" }),
            },
            {
              path: "/:site_name/use-service/edit-profile",
              name: "edit-profile",
              component: EditProfile,
              meta: new RouteMeta({ title: "회원정보 수정" }),
            },

            {
              path: "/:site_name/use-service/change-password",
              name: "change-password",
              component: ChangePassword,
              meta: new RouteMeta({ title: "비밀번호 변경" }),
            },
          ],
        },
        {
          path: "/:site_name/booking",
          name: "booking",
          component: BookingIndex,
          redirect: "/:site_name/booking/visit-store",
          meta: new RouteMeta({ title: "예약" }),
          children: [
            {
              path: "/:site_name/booking/visit-store",
              name: "visit-store",
              component: BookingList,
              meta: new RouteMeta({ title: "예약방문처리" }),
            },
            {
              path: "/:site_name/booking/approve-booking",
              name: "approve-booking",
              component: BookingRequestList,
              meta: new RouteMeta({ title: "예약신청승인" }),
            },
            {
              path: "/:site_name/booking/booking-calendar",
              name: "booking-calendar",
              component: BookingCalendar,
            },
          ],
        },
        {
          path: "/:site_name/notice/notice-list",
          name: "notice-list",
          component: NoticeIndex,
          redirect: "/:site_name/notice/notice-list",
          meta: new RouteMeta({ title: "공지사항" }),
          children: [
            {
              path: "/:site_name/notice/notice-list",
              name: "notice-list",
              component: NoticeList,
              meta: new RouteMeta({ title: "공지사항" }),
            },
            {
              path: "/:site_name/notice/notice-list/:notice_no",
              name: "notice-info",
              component: NoticeInfo,
              meta: new RouteMeta({ title: "공지사항" }),
            },
          ],
        },

        {
          path: "/:site_name/sales/sales-status",
          name: "sales-status",
          component: SalesIndex,
          redirect: "/:site_name/sales/sales-status",
          meta: new RouteMeta({ title: "매출내역" }),
          children: [
            {
              path: "/:site_name/sales/sales-status",
              name: "sales-status",
              component: SalesStatus,
              meta: new RouteMeta({ title: "매출내역" }),
            },
          ],
        },
      ],
    },
  ],
});

// This callback runs before every route change, including on initial load
router.beforeEach((to, from, next) => {
  const routeMeta = to.meta as RouteMeta;
  const siteName = to.params.site_name;

  console.log(`router: ${siteName}`);

  if (!siteName) {
    const storedSiteName = siteStore.siteName;
    if (storedSiteName) {
      next(`/${storedSiteName}`);
      return;
    }
  }

  siteStore.updateSiteName(siteName);
  siteStore.updateTitle(routeMeta.title);

  next();
});

export default router;
