
import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";
import BookingInfo from "@/components/booking/BookingInfo.vue";
import { bookingApi } from "@/api/service/bookingApi";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import { bookingUtils } from "@/lib/bookingUtils";
import { BookingDetailsDto } from "@/api/service/dto/order/bookingDetailsDto";
import { numberUtils } from "@/lib/numberUtils";

enum ConfirmAction {
  NoShow, BookingCancel, VisitStore
}

@Component({
  components: { BookingInfo, MessageSnackbar }
})
export default class BookingActionDialog extends Vue {
  @Prop() readonly booking!: BookingDetailsDto;
  @PropSync('showDialog', { type: Boolean }) syncShowDialog!: boolean;

  depositBookingAmount = 0;

  showConfirmAction = false;
  confirmAction: ConfirmAction | null = null;
  confirmActionMessage = '';
  needsConfirmActionChoice = false;
  showTypeAndResponseType = false;

  noPenalty = false;

  errorMessage: string | null = null;
  showErrorMessage = false;
  readonly errorMessageType = SnackbarMessageType.Error;
  type = null
  reasonType = null
  typeList = [
    {
      text: "매장",
      value: "PARTNER"
    }, {
      text: "구매자",
      value: "PURCHASER"
    }, {
      text: "기타",
      value: "ETC"
    },
  ]
  reasonTypeList = [

    {
      text: "구매자 요청",
      value: "PURCHASER_REQUEST"
    },
    {
      text: "재예약 진행",
      value: "RE_BOOKING"
    },
    {
      text: "건강상의 이유",
      value: "HEALTH_REASON"
    }, {
      text: "타사 최저가 이용",
      value: "THIRD_PARTY_LOWEST_PRICE"
    },
    {
      text: "단순 변심",
      value: "SIMPLE_REMORSE"
    },
    {
      text: "장소 운휴",
      value: "STORE_SUSPENSION"
    }, {
      text: "예약 마감",
      value: "BOOKING_CLOSED"
    },
    {
      text: "모객 부족",
      value: "SHORTAGE_OF_PURCHASERS"
    },
    {
      text: "천재지변",
      value: "NATURAL_DISASTER"
    },
    {
      text: "감염병 안전수칙 준수",
      value: "INFECTIOUS_DISEASE_SAFETY_RULES"
    },
  ]
  b2bChannelCode = ''

  get isBookingRequest(): boolean {
    return bookingUtils.isBookingRequest(this.booking.bookingStatus);
  }

  get isBooked(): boolean {
    return bookingUtils.isBooked(this.booking.bookingStatus);
  }

  get isVisited(): boolean {
    return bookingUtils.isVisited(this.booking.bookingStatus) && !this.booking.noShow;
  }

  get isNoShowVisited(): boolean {
    return bookingUtils.isVisited(this.booking.bookingStatus) && this.booking.noShow;
  }

  get enableCheckBox(): boolean {
    return this.isBooked && (this.confirmAction == null || this.confirmAction == ConfirmAction.VisitStore);
  }

  get actionTitle(): string {
    if (this.isBookingRequest) {
      return "예약승인";
    } else if (this.isBooked) {
      return "방문처리"
    } else if (this.isVisited) {
      return "방문처리 취소"
    } else if (this.isNoShowVisited) {
      return "노쇼(방문처리) 취소"
    } else {
      return "예약상태";
    }
  }

  get depositBookingAmountString(): string {
    return `선택된 예약금 합계: ${numberUtils.formatNumber(this.depositBookingAmount)}원`;
  }

  get timeSaleDiscountRateString(): string | null {
    if (this.booking.timeSaleRate) {
      const rate = parseInt(this.booking.timeSaleRate, 10);
      if (rate > 0) {
        return `타임세일: ${rate}%`;
      }
    }

    return null;
  }

  get cancellationFeeRate(): number {
    if (this.booking.cancellationFeeRate) {
      return parseInt(this.booking.cancellationFeeRate, 10);
    } else {
      return 0;
    }
  }

  get hasCancellationFee(): boolean {
    return this.cancellationFeeRate > 0;
  }

  get penaltyActionButtonColor(): string | null {
    if (this.confirmAction === ConfirmAction.NoShow) {
      // return "warning";
      return "var(--primary-main)";
    } else if (this.confirmAction === ConfirmAction.BookingCancel) {
      // return "error";
      return "var(--primary-main)";
    } else if (this.confirmAction === ConfirmAction.VisitStore) {
      // return "primary";
      return "var(--primary-main)";
    } else {
      return null;
    }
  }

  get penaltyActionButtonText(): string {
    if (this.confirmAction === ConfirmAction.NoShow) {
      // return "노쇼처리 확인";
      return "확인";
    } else if (this.confirmAction === ConfirmAction.BookingCancel) {
      // return "예약취소 확인";
      return "확인";
    } else if (this.confirmAction === ConfirmAction.VisitStore) {
      // return "방문완료 확인";
      return "확인";
    } else {
      return "";
    }
  }

  get showDepositAmountAndTimeSaleInfo(): boolean {
    return this.isBooked && this.booking.depositBooking && (this.confirmAction == null || this.confirmAction === ConfirmAction.VisitStore);
  }

  // get penaltyMessage(): string {
  //   if (this.penaltyConfirmAction === PenaltyConfirmAction.NoShow) {
  //     return `${this.cancellationFeeRate}% 위약금이 부과됩니다`;
  //   } else if (this.penaltyConfirmAction === PenaltyConfirmAction.BookingCancel) {
  //     return `${this.cancellationFeeRate}% 위약금이 부과됩니다`;
  //   } else if (this.penaltyConfirmAction === PenaltyConfirmAction.VisitStore) {
  //     return `사용하지 않은 쿠폰은 ${this.cancellationFeeRate}% 위약금이 부과됩니다`;
  //   } else {
  //     return "";
  //   }
  // }

  hasUncheckedItems(): boolean {
    for (const item of this.booking.orderItems) {
      if (!item.checked) {
        return true;
      }
    }

    return false;
  }

  private async acceptBookingRequest(): Promise<void> {
    try {
      await bookingApi.acceptBookingRequest(this.booking.bookingNo);
      this.requestAccepted();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // bookingApi.acceptBookingRequest(this.booking.bookingNo)
    //     .then(apiResponse => {
    //       this.requestAccepted();
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage);
    //     })
  }

  @Emit()
  private requestAccepted(): void {
    this.closeDialog();
  }

  private async rejectBookingRequest(): Promise<void> {
    try {
      await bookingApi.rejectBookingRequest(this.booking.bookingNo);
      this.requestRejected();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // bookingApi.rejectBookingRequest(this.booking.bookingNo)
    //     .then(apiResponse => {
    //       this.requestRejected();
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage);
    //     })
  }

  @Emit()
  private requestRejected(): void {
    this.closeDialog();
  }

  async onClickBookingCancel(): Promise<void> {
    console.log('onClickBookingCancel');

    try {
      const response = await bookingApi.bookingCancelRefundAmount(this.booking.bookingNo);
      const refundAmount = response.data;

      this.showConfirmAction = true;
      this.showTypeAndResponseType = true;
      this.confirmAction = ConfirmAction.BookingCancel;
      this.needsConfirmActionChoice = false;
      this.b2bChannelCode = refundAmount?.b2bChannelCode === null ? '' : refundAmount!.b2bChannelCode

      if (refundAmount) {
        if (refundAmount.bookingPenaltyRate) {
          const penaltyRate = parseInt(refundAmount.bookingPenaltyRate, 10);
          if (penaltyRate !== 0) {
            this.confirmActionMessage = `${penaltyRate}% 예약취소 위약금이 부과됩니다`;
            this.needsConfirmActionChoice = true;
            return;
          }
        }

        this.confirmActionMessage = '예약취소 하시겠습니까?';
        // await this.cancelBooking();
      } else {
        this.toastErrorMessage('예약취소 가능여부를 조회하지 못하였습니다.');
        return;
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }
  }

  private async cancelBooking(): Promise<void> {
    if (this.b2bChannelCode == 'KAKAOB') {
      if (!this.type || !this.reasonType) {
        alert("취소 사유는 필수 선택 입니다")
        return
      }
    }
    try {
      const noPenalty = this.needsConfirmActionChoice && this.noPenalty;
      await bookingApi.cancelBooking(this.booking.bookingNo, noPenalty, this.type, this.reasonType);
      this.bookingCancelled();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // bookingApi.cancelBooking(this.booking.bookingNo)
    //     .then(apiResponse => {
    //       this.bookingCancelled();
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage);
    //     })
  }

  @Emit()
  private bookingCancelled(): void {
    this.closeDialog();
  }

  async onClickNoShow(): Promise<void> {
    console.log('onClickNoShow');

    try {
      const response = await bookingApi.noShowRefundAmount(this.booking.bookingNo);
      const refundAmount = response.data;

      this.showConfirmAction = true;
      this.showTypeAndResponseType = true;
      this.confirmAction = ConfirmAction.NoShow;
      this.needsConfirmActionChoice = false;

      if (refundAmount) {
        if (refundAmount.bookingPenaltyRate) {
          const penaltyRate = parseInt(refundAmount.bookingPenaltyRate, 10);
          if (penaltyRate !== 0) {
            this.confirmActionMessage = `${penaltyRate}% 노쇼 위약금이 부과됩니다`;
            this.needsConfirmActionChoice = true;
            return;
          }
        }

        this.confirmActionMessage = '노쇼처리 하시겠습니까?';
        // await this.setNoShow();
      } else {
        this.toastErrorMessage('노쇼처리 가능여부를 조회하지 못하였습니다.');
        return;
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }
  }

  private async setNoShow(): Promise<void> {
    if (this.b2bChannelCode == 'KAKAOB') {
      if (!this.type || !this.reasonType) {
        alert("취소 사유는 필수 선택 입니다")
        return
      }
    }
    try {
      await bookingApi.noShow(this.booking.bookingNo, this.noPenalty, this.type, this.reasonType);
      this.hadNoShow();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // bookingApi.noShow(this.booking.bookingNo)
    //     .then(apiResponse => {
    //       this.hadNoShow();
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage);
    //     })
  }

  @Emit()
  private hadNoShow(): void {
    this.closeDialog();
  }

  onClickVisitStore(): void {
    this.showConfirmAction = true;
    this.confirmAction = ConfirmAction.VisitStore;
    this.needsConfirmActionChoice = false;

    if (this.hasUncheckedItems()) {
      if (this.hasCancellationFee) {
        this.confirmActionMessage = `사용하지 않은 쿠폰은 ${this.cancellationFeeRate}% 위약금이 부과됩니다`;
        this.needsConfirmActionChoice = true;
        return;
      } else {
        this.confirmActionMessage = '선택하지 않은 쿠폰이 있습니다. 방문완료처리 하시겠습니까?';
        return;
      }
    }

    this.completeVisit();
  }

  private async completeVisit(): Promise<void> {
    try {
      await bookingApi.visitStore(this.booking.bookingNo, this.checkedOrderItems(), this.noPenalty);
      this.visitCompleted();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // bookingApi.visitStore(this.booking.bookingNo, this.checkedOrderItems())
    //     .then(apiResponse => {
    //       this.visitCompleted();
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage);
    //     })
  }

  @Emit()
  private visitCompleted(): void {
    this.closeDialog();
  }

  private cancelVisit(): void {
    return;
  }

  @Emit()
  private visitCancelled(): void {
    this.closeDialog();
  }

  private checkedOrderItems(): string[] {
    return this.booking.orderItems
      .filter(orderItem => orderItem.checked)
      .map(orderItem => orderItem.itemNo);
  }

  onCancelConfirmAction(): void {
    this.showConfirmAction = false;
    this.confirmAction = null;
    this.noPenalty = false;
    this.confirmActionMessage = '';
    this.needsConfirmActionChoice = false;
  }

  onClickConfirmAction(): void {
    console.log('onClickPenaltyAction');

    if (this.confirmAction === ConfirmAction.NoShow) {
      this.setNoShow();
    } else if (this.confirmAction === ConfirmAction.BookingCancel) {
      this.cancelBooking();
    } else if (this.confirmAction === ConfirmAction.VisitStore) {
      this.completeVisit();
    }
  }

  onChangeDepositAmount(amount: number): void {
    this.depositBookingAmount = amount;
  }

  private closeDialog(): void {
    this.syncShowDialog = false;
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
}
