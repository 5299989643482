export enum BookingStatusEnum {
  BookingRequested = "BookingRequested",
  BookingRejected = "BookingRejected",
  Booked = "Booked",
  CancelRequested = "CancelRequested",
  Cancelled = "Cancelled",
  Visited = "Visited",
  NoShow = "NoShow",
}

export enum BookingStatusTimeUnitEnum {
  M30 = "M30",
  M60 = "M60",
}
