
import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";



@Component({

})
export default class BookingCalendarMonthDialog extends Vue {
  @PropSync('date', { type: String }) syncDate!: string;
  @PropSync('showDialog', { type: Boolean }) syncShowDialog!: boolean;

  @Emit()
  onClickDate(selectedDate: string): string {
    this.syncShowDialog = false
    return `${selectedDate}-01`
  }
}
