
import { Component, Vue } from "vue-property-decorator";
import BookingRow from "@/components/booking/BookingRow.vue";
import { bookingApi } from "@/api/service/bookingApi";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import BookingActionDialog from "@/views/booking/components/BookingActionDialog.vue";
import { BookingRequestInfoDto } from "@/api/service/dto/order/bookingRequestListDto";
import { BookingDetailsDto } from "@/api/service/dto/order/bookingDetailsDto";
import { BookingCommonDto } from "@/api/service/dto/order/bookingCommonDto";

@Component({
  components: { BookingRow, MessageSnackbar }
})
export default class BookingRequestList extends Vue {
  private bookingRequests: BookingRequestInfoDto[] = [];

  private selectedBooking: BookingDetailsDto | null = null;
  private shouldShowBookingActionDialog = false;

  private hasNoBookingRequest = false;

  errorMessage: string | null = null;
  showErrorMessage = false;
  errorMessageType = SnackbarMessageType.Error;

  mounted(): void {
    this.searchBookingRequests();
  }

  get bookingActionDialog(): Vue.Component | null {
    return this.shouldShowBookingActionDialog ? BookingActionDialog : null;
  }

  private async searchBookingRequests(): Promise<void> {
    this.hasNoBookingRequest = false;

    this.bookingRequests = [];

    try {
      const response = await bookingApi.searchBookingRequests();

      this.bookingRequests = response.data ?? [];

      if (this.bookingRequests.length === 0) {
        this.hasNoBookingRequest = true;
      }

      console.log(this.bookingRequests);
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // bookingApi.searchBookingRequests()
    //     .then(apiResponse => {
    //       this.bookingRequests = apiResponse.data ?? [];
    //
    //       if (this.bookingRequests.length === 0) {
    //         this.hasNoBookingRequest = true;
    //       }
    //
    //       console.log(this.bookingRequests);
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Error);
    //     })
  }

  private removeSelectedBookingRequest(): void {
    if (!this.selectedBooking?.bookingNo) {
      return;
    }

    const bookingIndex = this.bookingRequests.findIndex(booking => booking.bookingNo === this.selectedBooking?.bookingNo);
    if (bookingIndex >= 0) {
      this.bookingRequests.splice(bookingIndex, 1);
      this.$forceUpdate();
    }
  }

  private async onClickBooking(booking: BookingCommonDto): Promise<void> {
    try {
      const response = await bookingApi.queryBookingDetails(booking.bookingNo);

      this.selectedBooking = response.data?.bookingDetails ?? null;
      this.shouldShowBookingActionDialog = true;
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // bookingApi.queryBookingDetails(booking.bookingNo)
    //     .then(apiResponse => {
    //       this.selectedBooking = apiResponse.data?.bookingDetails ?? null;
    //       this.shouldShowBookingActionDialog = true;
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Error);
    //     })
  }

  private bookingRequestRejected(): void {
    this.toastErrorMessage("예약신청이 거절되었습니다.", SnackbarMessageType.Info);
    // this.shouldShowApproveBookingDialog = false;

    this.removeSelectedBookingRequest();
  }

  private bookingRequestAccepted(): void {
    this.toastErrorMessage("예약신청이 승인되었습니다.", SnackbarMessageType.Info);
    // this.shouldShowApproveBookingDialog = false;

    this.removeSelectedBookingRequest();
  }

  private toastErrorMessage(message: string, messageType: SnackbarMessageType) {
    this.errorMessage = message;
    this.errorMessageType = messageType;
    this.showErrorMessage = true;
  }
}
