
import { Component, Emit, Vue } from "vue-property-decorator";
import { userApi } from "@/api/service/userApi";
import { userStore } from "@/store";
import { stringUtils } from "@/lib/stringUtils";
import { UserRoleEnum } from "@/api/service/type/userRoleEnum";

@Component
export default class OtpLogin extends Vue {
  username = "";
  otpPassword = "";
  phoneNumber = "";

  usernameErrorMessage: string | null = null;
  otpPasswordErrorMessage: string | null = null;
  phoneNumberErrorMessage: string | null = null;

  // errorMessage:string|null = null;

  otpRequested = false;

  valid = false;

  get buttonCaption(): string {
    return this.otpRequested ? "임시 비밀번호 로그인" : "임시 비밀번호 요청";
  }

  onClickButton(): void {
    if (this.otpRequested) {
      this.otpLogin();
    } else {
      this.requestOtp();
    }
  }

  @Emit()
  userLogin(): void {
    return;
  }

  @Emit()
  onError(message: string): string {
    return message;
  }

  async requestOtp(): Promise<void> {
    if (!this.validateOtpInput()) {
      return;
    }

    try {
      await userApi.requestOtp(this.username, this.phoneNumber);
      this.otpRequested = true;
    } catch (e) {
      const error = e as Error;
      //this.onError(error.message);
      /* if (error.message == '등록되지 않은 관리자입니다.') {
        this.usernameErrorMessage = error.message
      } else if (error.message == '전화번호가 맞지 않습니다.') {
        this.phoneNumberErrorMessage = error.message
      } else { */
      this.onError(error.message);
      /*   } */
    }

    // userApi.requestOtp(this.username, this.phoneNumber)
    //     .then(apiResponse => {
    //       this.otpRequested = true;
    //     })
    //     .catch(message => {
    //       this.onError(message);
    //     });

    return;
  }

  async otpLogin(): Promise<void> {
    if (!this.validateLoginInput()) {
      return;
    }

    try {
      const response = await userApi.otpLogin(this.username, this.otpPassword);
      const loginReply = response.data;

      if (loginReply) {
        if (loginReply.userRole === UserRoleEnum.STORE_MANAGER || loginReply.userRole === UserRoleEnum.STORE_OWNER) {
          await userStore.updateStaySignedIn(false);
          await userStore.updateUserAuth({ staySignedIn: false, userToken: loginReply });
        } else {
          this.onError("매장 관리자만 이용할 수 있습니다.");
        }
      } else {
        this.onError('로그인 응답에 토큰 정보가 없습니다.');
      }
    } catch (e) {
      const error = e as Error;
      /*  if (error.message == '등록되지 않은 관리자가 로그인을 시도하였습니다.') {
         this.usernameErrorMessage = error.message
       } else if (error.message == '전화번호가 맞지 않습니다.') {
         this.phoneNumberErrorMessage = error.message
       } else {
         this.onError(error.message);
       } */
      this.onError(error.message);
    }

    // userApi.otpLogin(this.username, this.otpPassword)
    //     .then(async apiResponse => {
    //       console.log(apiResponse);
    //
    //       const userToken = apiResponse.data;
    //       if (userToken) {
    //         if( userToken.userRole === UserRoleEnum.STORE_MANAGER || userToken.userRole === UserRoleEnum.STORE_OWNER) {
    //           await userStore.updateStaySignedIn(false);
    //           await userStore.updateUserAuth({staySignedIn: false, userToken: userToken});
    //         } else {
    //           this.onError("매장 관리자만 이용할 수 있습니다.");
    //         }
    //       }
    //     })
    //     .catch(message => {
    //       this.onError(message);
    //     });
  }

  private validateOtpInput(): boolean {
    let valid = true;

    if (stringUtils.isBlank(this.username)) {
      this.usernameErrorMessage = "아이디를 입력하세요.";
      valid = false;
    }

    if (stringUtils.isBlank(this.phoneNumber)) {
      this.phoneNumberErrorMessage = "전화번호를 입력하세요."
      valid = false;
    }

    return valid;
  }

  private validateLoginInput(): boolean {
    let valid = true;

    if (stringUtils.isBlank(this.username)) {
      this.usernameErrorMessage = "아이디를 입력하세요.";
      valid = false;
    }

    if (stringUtils.isBlank(this.otpPassword)) {
      this.otpPasswordErrorMessage = "임시 비밀번호를 입력하세요."
      valid = false;
    }

    return valid;
  }
}
