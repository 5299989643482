
import { Component, Vue } from "vue-property-decorator";
import CouponRow from "@/components/coupon/CouponRow.vue";
import UseCouponDialog from "@/views/coupon/components/UseCouponDialog.vue";
import UseMultipleCouponsDialog from "@/views/coupon/components/UseMultipleCouponsDialog.vue";
import CancelUseCouponDialog from "@/views/coupon/components/CancelUseCouponDialog.vue";
import { CouponProps } from "@/components/coupon/type/CouponProps";
import { couponApi } from "@/api/service/couponApi";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import { CouponTransactionProps, CouponTransactionPropsUtil } from "@/components/coupon/type/CouponTransactionProps";
import { couponUtils } from "@/lib/couponUtils";
import { CouponListKeywordTypeEnum } from "@/api/service/dto/order/couponListDto";
import { CouponUseTransactionDto } from "@/api/service/dto/order/couponUseInfoDto";

@Component({
  components: { CancelUseCouponDialog, UseCouponDialog, UseMultipleCouponsDialog, CouponRow, MessageSnackbar }
})
export default class CouponList extends Vue {
  private readonly keywordTypes = [
    { text: "전화번호 끝 4자리", value: CouponListKeywordTypeEnum.PhoneNumber4 },
    { text: "전화번호", value: CouponListKeywordTypeEnum.PhoneNumber },
    { text: "쿠폰번호", value: CouponListKeywordTypeEnum.ItemNo },
  ];
  private keywordType = CouponListKeywordTypeEnum.PhoneNumber4;
  private keyword: string | null = null;

  private keywordErrorMessage: string | null = null;

  private couponList: CouponProps[] = [];
  private selectedCoupon: CouponProps | null = null;
  private useTransactions: CouponTransactionProps[] = [];

  private shouldShowUseCouponDialog = false;
  private shouldShowUseMultipleCouponsDialog = false;
  private shouldShowCancelUseCouponDialog = false;

  private hasNoCoupon = false;

  errorMessage: string | null = null;
  showErrorMessage = false;
  errorMessageType = SnackbarMessageType.Error;

  textFieldPlaceHolder: string | null = '전화번호 뒷 4자리를 입력해주세요.';


  private consumedCouponList: CouponProps[] = [];
  private nonConsumedCouponList: CouponProps[] = [];

  private selectedCouponList: CouponProps[] = [];

  mounted(): void {
    console.log('mounted');
  }

  get useCouponDialog(): Vue.Component | null {
    return this.shouldShowUseCouponDialog ? UseCouponDialog : null;
  }

  get useMultipleCouponsDialog(): Vue.Component | null {
    return this.shouldShowUseMultipleCouponsDialog ? UseMultipleCouponsDialog : null;
  }

  get cancelUseCouponDialog(): Vue.Component | null {
    return this.shouldShowCancelUseCouponDialog ? CancelUseCouponDialog : null;
  }

  // private refresh(): void {
  //   if (this.keyword) {
  //     this.searchCoupons();
  //   }
  // }

  private async searchCoupons(): Promise<void> {
    this.hasNoCoupon = false;
    this.selectedCouponList = []

    if (!this.keyword) {
      this.keywordErrorMessage = "검색어를 입력하세요."
      return;
    }

    this.couponList = []
    this.consumedCouponList = []
    this.nonConsumedCouponList = []

    try {

      this.couponList = [];
      const response = await couponApi.searchCoupons(this.keywordType, this.keyword);
      this.couponList = couponUtils.toCouponProps(response.data);

      if (this.couponList.length === 0) {
        this.hasNoCoupon = true;
      }

      console.log(this.couponList);

      for (let i = 0; i < this.couponList.length; i++) {
        if (this.couponProps(this.couponList[i]).itemStatus == 'Consumed') {
          this.consumedCouponList.push(this.couponProps(this.couponList[i]))
        } else {
          this.nonConsumedCouponList.push(this.couponProps(this.couponList[i]))
        }
      }
      this.couponList = [
        ...this.nonConsumedCouponList,
        ...this.consumedCouponList
      ]


    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // couponApi.searchCoupons(this.keywordType, this.keyword)
    //     .then(apiResponse => {
    //       this.couponList = couponUtils.toCouponProps(apiResponse.data);
    //
    //       if (this.couponList.length === 0) {
    //         this.hasNoCoupon = true;
    //       }
    //
    //       console.log(this.couponList);
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Error);
    //     })
  }

  private async refreshSelectedItem(): Promise<void> {

    if (!this.selectedCoupon?.itemNo && !this.selectedCouponList[0]?.itemNo) {
      return;
    }

    try {
      if (this.selectedCouponList[0]?.itemNo) {
        for (let i = 0; i < this.selectedCouponList?.length; i++) {
          const response = await couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.selectedCouponList[i]?.itemNo);
          let updatedCoupon = couponUtils.findMatchingCoupon(response.data, this.selectedCouponList[i]?.itemNo);
          if (updatedCoupon == null) {
            return;
          }

          const targetIndex = this.couponList.findIndex(coupon => coupon.itemNo === this.selectedCouponList[i]?.itemNo);

          if (targetIndex < 0) {
            return;
          }

          this.couponList[targetIndex] = updatedCoupon;
        }
        // this.couponList.splice(targetIndex, 1, updatedCoupon);
      }
      else if (this.selectedCoupon?.itemNo) {
        const response = await couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.selectedCoupon.itemNo);
        let updatedCoupon = couponUtils.findMatchingCoupon(response.data, this.selectedCoupon?.itemNo);
        if (updatedCoupon == null) {
          return;
        }

        const targetIndex = this.couponList.findIndex(coupon => coupon.itemNo === this.selectedCoupon?.itemNo);
        if (targetIndex < 0) {
          return;
        }

        this.couponList[targetIndex] = updatedCoupon;
        // this.couponList.splice(targetIndex, 1, updatedCoupon);
      }
      this.$forceUpdate();
    } catch (e) {
      const error = e as Error;
      console.log(error.message);
    } finally {
      if (this.selectedCouponList.length !== 0)
        this.selectedCouponList = []
    }

    // couponApi.searchCoupons(CouponListKeywordTypeEnum.ItemNo, this.selectedCoupon.itemNo)
    //     .then(apiResponse => {
    //       let updatedCoupon = couponUtils.findMatchingCoupon(apiResponse.data, this.selectedCoupon?.itemNo);
    //       if (updatedCoupon == null) {
    //         return;
    //       }
    //
    //       const targetIndex = this.couponList.findIndex(coupon => coupon.itemNo === this.selectedCoupon?.itemNo);
    //       if (targetIndex < 0) {
    //         return;
    //       }
    //
    //       this.couponList[targetIndex] = updatedCoupon;
    //       // this.couponList.splice(targetIndex, 1, updatedCoupon);
    //       this.$forceUpdate();
    //     })
    //     .catch(message => {
    //       console.log(message);
    //     })
  }

  // private toCouponProps(orders: GenericObject | null | undefined): CouponProps[] {
  //   if (!orders) {
  //     return [];
  //   }
  //
  //   let couponProps: CouponProps[] = [];
  //
  //   orders.forEach((order: GenericObject) => {
  //     let coupons = order.orderItems
  //         .filter((item: GenericObject) => item.itemStatus === 'Available' || item.itemStatus === 'Consumed')
  //         .map((item: GenericObject) => {
  //           item.orderNo = order.orderNo;
  //           return item;
  //         });
  //
  //     couponProps.push(...coupons);
  //   });
  //
  //   return couponProps;
  // }
  //
  // private findMatchingCoupon(orders: GenericObject|null|undefined, itemNo:string|null|undefined): CouponProps|null {
  //   if (!itemNo) {
  //     return null;
  //   }
  //
  //   let couponProps = this.toCouponProps(orders);
  //   if (couponProps.length === 0) {
  //     return null;
  //   }
  //
  //   const index = couponProps.findIndex(coupon => coupon.itemNo === itemNo);
  //   if (index < 0) {
  //     return null;
  //   }
  //
  //   return couponProps[index];
  // }

  // private onClickCoupon(coupon: CouponProps) {
  //   if (coupon.itemStatus === 'Available') {
  //     this.showUseCouponDialog(coupon);
  //   } else if (coupon.itemStatus === 'Consumed') {
  //     this.showCancelUseCouponDialog(coupon);
  //   }
  // }

  onClickUseCouponList(): void {
    if (couponUtils.isMoneyCoupon(this.selectedCouponList[0].goodsType)) {
      this.showUseCouponListDialog()
    } else if (couponUtils.isMobileCoupon(this.selectedCouponList[0].goodsType)) {
      this.useMobileCoupon();
    } else if (couponUtils.isBookingCoupon(this.selectedCouponList[0].goodsType)) {
      this.useBookingCoupon()
    }
  }

  onClickUseCoupon(coupon: CouponProps): void {
    this.showUseCouponDialog(coupon);
  }

  onClickUseCoupons(coupon: CouponProps): void {
    this.showUseCouponDialog(coupon);
  }

  onClickCancelUseCoupon(coupon: CouponProps): void {
    this.showCancelUseCouponDialog(coupon);
  }

  onClickSelectCoupon(coupon: CouponProps): void {
    const index = this.selectedCouponList.findIndex(item => item.itemNo == coupon.itemNo)
    if (index == -1) {
      this.selectedCouponList.push(coupon)
    } else {
      this.selectedCouponList.splice(index, 1)
    }
    this.selectedCouponList.sort((a, b) => Number(b.orderNo) - Number(a.orderNo))

  }

  private showUseCouponDialog(coupon: CouponProps): void {
    this.selectedCoupon = coupon;
    this.shouldShowUseCouponDialog = true;
  }

  private showUseCouponListDialog() {
    this.shouldShowUseMultipleCouponsDialog = true;
  }

  // private closeUseCouponDialog(shouldRefresh: boolean): void {
  //   this.shouldShowUseCouponDialog = false;
  //
  //   if (shouldRefresh) {
  //     this.refresh();
  //   }
  // }

  private showCancelUseCouponDialog(coupon: CouponProps): void {
    this.queryTransactionsAndShowDialog(coupon);
  }

  private onUseCancelled() {
    // this.refresh(true);
    this.refreshSelectedItem();
    this.toastErrorMessage("사용처리가 취소되었습니다.", SnackbarMessageType.Info)
  }

  private onUseCompleted(): void {
    // this.refresh(true);
    this.refreshSelectedItem();
    this.toastErrorMessage("사용처리 되었습니다.", SnackbarMessageType.Info)
  }

  private async useMobileCoupon(): Promise<void> {
    try {
      for (let i = 0; i < this.selectedCouponList?.length; i++) {
        await couponApi.useMobileCoupon(this.selectedCouponList[i]?.orderNo, this.selectedCouponList[i]?.itemNo);
      }
      this.onUseCompleted()
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }

    // couponApi.useMobileCoupon(this.coupon.orderNo, this.coupon.itemNo)
    //     .then(apiResponse => {
    //       this.useCompleted();
    //     })
    //     .catch(message => {
    //       alert(message);
    //     });
  }

  private async useBookingCoupon(): Promise<void> {
    try {
      for (let i = 0; i < this.selectedCouponList?.length; i++) {
        await couponApi.useBookingCoupon(this.selectedCouponList[i]?.orderNo, this.selectedCouponList[i]?.itemNo);
      }
      this.onUseCompleted()
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }

    // couponApi.useBookingCoupon(this.coupon.orderNo, this.coupon.itemNo)
    //     .then(apiResponse => {
    //       this.useCompleted();
    //     })
    //     .catch(message => {
    //       alert(message);
    //     });
  }


  private async queryTransactionsAndShowDialog(coupon: CouponProps): Promise<void> {
    this.useTransactions = [];

    try {
      const response = await couponApi.queryCouponUseInfo(coupon.itemNo);
      const useInfo = response.data;

      if (useInfo && useInfo.transactions.length > 0) {
        this.useTransactions = useInfo.transactions
          .filter((transaction: CouponUseTransactionDto) => !transaction.cancelled)
          .map((transaction: CouponUseTransactionDto) => {
            return CouponTransactionPropsUtil.toTransactionPropsFromDto(transaction, useInfo.itemNo, useInfo.goodsType);
            // transaction.itemNo = apiResponse.data?.itemNo;
            // transaction.goodsType = apiResponse.data?.goodsType;
            // return transaction;
          })

        this.selectedCoupon = coupon;
        this.shouldShowCancelUseCouponDialog = true;
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // couponApi.queryCouponUseInfo(coupon.itemNo)
    //     .then(apiResponse => {
    //       const useInfo = apiResponse.data;
    //
    //       if (useInfo && useInfo.transactions.length > 0) {
    //         this.useTransactions = useInfo.transactions
    //             .filter((transaction: CouponUseTransactionDto) => !transaction.cancelled)
    //             .map((transaction: CouponUseTransactionDto) => {
    //               return CouponTransactionPropsUtil.toTransactionPropsFromDto(transaction, useInfo.itemNo, useInfo.goodsType);
    //               // transaction.itemNo = apiResponse.data?.itemNo;
    //               // transaction.goodsType = apiResponse.data?.goodsType;
    //               // return transaction;
    //             })
    //
    //         this.selectedCoupon = coupon;
    //         this.shouldShowCancelUseCouponDialog = true;
    //       }
    //     })
    //     .catch(message => {
    //       this.toastErrorMessage(message, SnackbarMessageType.Error);
    //     })
  }

  couponProps(coupon: CouponProps): CouponProps {
    return coupon;
  }

  private toastErrorMessage(message: string, messageType: SnackbarMessageType) {
    this.errorMessage = message;
    this.errorMessageType = messageType;
    this.showErrorMessage = true;
  }

  private showPlaceHolderMessage() {
    switch (this.keywordType) {
      case this.keywordTypes[0].value: this.textFieldPlaceHolder = '전화번호 뒷 4자리를 입력해주세요.'; break;
      case this.keywordTypes[1].value: this.textFieldPlaceHolder = '전화번호를 입력해주세요.'; break;
      case this.keywordTypes[2].value: this.textFieldPlaceHolder = '쿠폰번호를 입력해주세요.'; break;
    }
  }
}

