
import { Component, Prop, Vue } from "vue-property-decorator";
import { dateUtils } from "@/lib/dateUtils";
import { StoreVisitInfoDto } from "@/api/service/dto/order/storeVisitInfoDto";
import { BookingTypeEnum } from "@/api/service/type/bookingTypeEnum";
import { numberUtils } from "@/lib/numberUtils";

@Component
export default class BookingVisitInfo extends Vue {
  @Prop() readonly visit!: StoreVisitInfoDto;

  get classBookingAttrKey(): string {
    // return "body-1 text--secondary text-end pa-1 ma-0";
    return "attrKey primary-black text-start pl-4  py-1 pr-1 ma-0 ";
  }

  get classBookingAttrValue(): string {
    // return "body-1 pa-1 ma-0 px-3";
    return "attrValue  primary-black  pr-4 pl-3 py-1 ma-0";
  }

  get bookingAttrKeyCols(): string {
    return "3";
  }

  get bookingAttrValueCols(): string {
    return "9";
  }

  get bookingNumber(): string {
    if (this.visit.bookingType === BookingTypeEnum.ManualBooking) {
      return this.visit.bookingNo + " (수기예약)";
    } else {
      return this.visit.bookingNo;
    }
  }

  get visitDateTime(): string {
    return dateUtils.formatDateTime(this.visit.visitDate);
  }

  get cancellable(): string {
    return this.visit.cancellable ? "가능" : "불가능";
  }

  get timeSaleRate(): number | null {
    if (this.visit.timeSaleRate) {
      return parseInt(this.visit.timeSaleRate, 10);
    } else {
      return null;
    }
  }

  get depositAmount(): number | null {
    if (this.visit.depositBooking && this.visit.depositAmountTotal) {
      return parseInt(this.visit.depositAmountTotal, 10);
    } else {
      return null;
    }
  }

  get depositAmountString(): string | null {
    if (this.depositAmount) {
      return `${numberUtils.formatNumber(this.depositAmount)}원`
    } else {
      return null;
    }
  }
}
