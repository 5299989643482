
import { Component, Emit, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import CouponRow from "@/components/coupon/CouponRow.vue";
import { couponUtils } from "@/lib/couponUtils";
import { CouponProps } from "@/components/coupon/type/CouponProps";
import CouponInfo from "@/components/coupon/CouponInfo.vue";
import { couponApi } from "@/api/service/couponApi";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import MoneyInput from "@/components/common/MoneyInput.vue";

@Component({
  components: { MoneyInput, CouponInfo, CouponRow, MessageSnackbar }
})
export default class UseMultipleCouponsDialog extends Vue {
  @Prop() readonly couponList!: CouponProps[];
  @Prop() readonly initWatch!: Date;
  @PropSync('showDialog', { type: Boolean }) syncShowDialog!: boolean;

  multiple = true;

  private useAmount: string | null = null;
  private useAmountErrorMessage: string | null = null;

  private maxAmount: string | null = null;

  errorMessage: string | null = null;
  showErrorMessage = false;
  chkModalToggle = false;
  chkModalContent = false;
  readonly errorMessageType = SnackbarMessageType.Error;


  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.couponList[0].goodsType);
  }

  get couponTypeName(): string {
    return couponUtils.goodsTypeCaption(this.couponList[0].goodsType);
  }

  get couponProps(): CouponProps {
    return this.couponList[0] as CouponProps;
  }

  get canUse(): boolean {
    return couponUtils.canUse(this.couponList[0]);
  }

  get canUse5Days(): boolean {
    return couponUtils.canUse5Days(this.couponList[0]);
  }

  mounted() {
    let total = 0
    for (let i = 0; i < this.couponList.length; i++) {
      total += Number(this.couponList[i].usableAmount)
    }
    this.maxAmount = total.toString()

  }

  accept(): void {
    /* if (couponUtils.isMobileCoupon(this.couponList[0].goodsType)) {
      this.useMobileCoupon();
    } else if (couponUtils.isBookingCoupon(this.couponList[0].goodsType)) {
      this.useBookingCoupon();
    } else */ if (couponUtils.isMoneyCoupon(this.couponList[0].goodsType)) {
      this.useMoneyCoupon();
    }

  }

  @Watch("initWatch")
  onInitWatchUpdated(val: Date, oldVal: Date): void {
    this.useAmount = null;
    this.useAmountErrorMessage = null;
  }

  @Emit()
  private useCompleted(): void {
    this.closeDialog();
  }

  private closeDialog(): void {
    this.syncShowDialog = false;
  }

  private async useMobileCoupon(): Promise<void> {
    try {
      await couponApi.useMobileCoupon(this.couponList[0].orderNo, this.couponList[0].itemNo);
      this.useCompleted();
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }

    // couponApi.useMobileCoupon(this.coupon.orderNo, this.coupon.itemNo)
    //     .then(apiResponse => {
    //       this.useCompleted();
    //     })
    //     .catch(message => {
    //       alert(message);
    //     });
  }

  private async useBookingCoupon(): Promise<void> {
    try {
      await couponApi.useBookingCoupon(this.couponList[0].orderNo, this.couponList[0].itemNo);
      this.useCompleted();
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }

    // couponApi.useBookingCoupon(this.coupon.orderNo, this.coupon.itemNo)
    //     .then(apiResponse => {
    //       this.useCompleted();
    //     })
    //     .catch(message => {
    //       alert(message);
    //     });
  }

  private async useMoneyCoupon(): Promise<void> {
    if (!this.useAmount) {
      this.useAmountErrorMessage = "사용금액을 입력하세요.";
      return;
    } else if (parseInt(this.useAmount, 10) > parseInt(this.maxAmount!, 10)) {
      this.useAmountErrorMessage = "입력한 금액이 사용가능한 금액보다 많습니다."
      return;
    }

    try {
      let difference = parseInt(this.useAmount, 10)
      for (let i = 0; i < this.couponList.length; i++) {
        if (parseInt(this.couponList[i].usableAmount, 10) < difference) {
          await couponApi.useMoneyCoupon(this.couponList[i].orderNo, this.couponList[i].itemNo, this.couponList[i].usableAmount);
          difference -= parseInt(this.couponList[i].usableAmount, 10)
        } else {
          await couponApi.useMoneyCoupon(this.couponList[i].orderNo, this.couponList[i].itemNo, difference.toString());
          break;
        }
      }
      //await couponApi.useMoneyCoupon(this.couponList[i].orderNo, this.couponList[i].itemNo, difference.toString());
      this.useCompleted();
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message);
    }

    // couponApi.useMoneyCoupon(this.coupon.orderNo, this.coupon.itemNo, this.useAmount)
    //     .then(apiResponse => {
    //       this.useCompleted();
    //     })
    //     .catch(message => {
    //       this.toastErrorMessage(message);
    //     });
  }

  private onAmountChanged(amount: string | null) {
    this.useAmount = amount;
  }

  private toastErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
  private openChkModal() {
    this.chkModalContent = false;
    if (couponUtils.isMoneyCoupon(this.couponList[0].goodsType)) {
      if (!this.useAmount) {
        this.useAmountErrorMessage = "사용금액을 입력하세요.";
        return;
      } else if (this.maxAmount && parseInt(this.useAmount, 10) > parseInt(this.maxAmount, 10)) {
        this.useAmountErrorMessage = "입력한 금액이 사용가능한 금액보다 많습니다."
        return;
      }
    }
    if (!this.canUse5Days) {
      this.showErrorMessage = true
      this.errorMessage = "유효기간 만료일 +5일 이후에는 사용이 불가합니다. (사용처리 문의 : 02-6952-0852)";
      return;
    } else {
      if (!this.canUse) {
        let changeBack = document.getElementsByClassName("v-overlay__scrim")[0] as HTMLElement;
        let changeMainCard = document.getElementById("mainCard") as HTMLElement;
        changeBack.style.opacity = "0.8";
        changeMainCard.style.opacity = "0.7";
        this.chkModalToggle = true;
      } else {
        this.accept();
      }
    }

  }
  private closeChkModal() {
    let changeBack = document.getElementsByClassName("v-overlay__scrim")[0] as HTMLElement;
    let changeMainCard = document.getElementById("mainCard") as HTMLElement;
    changeBack.style.opacity = "0.46";
    changeMainCard.style.opacity = "1";
    this.chkModalToggle = false;
    this.chkModalContent = false;
  }
}

