
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { bookingApi } from "@/api/service/bookingApi";
import BookingRow from "@/components/booking/BookingRow.vue";
import BookingActionDialog from "@/views/booking/components/BookingActionDialog.vue";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import { bookingUtils } from "@/lib/bookingUtils";
import CancelVisitDialog from "@/views/booking/components/CancelVisitDialog.vue";
import { dateUtils } from "@/lib/dateUtils";
import { BookingDateTypeEnum, BookingInfoDto, BookingListKeywordTypeEnum } from "@/api/service/dto/order/bookingListDto";
import { BookingDetailsDto } from "@/api/service/dto/order/bookingDetailsDto";
import { StoreVisitInfoDto } from "@/api/service/dto/order/storeVisitInfoDto";
import { BookingCommonDto } from "@/api/service/dto/order/bookingCommonDto";
import DatePicker from "@/components/common/DatePicker.vue";

@Component({
  components: { BookingRow, MessageSnackbar }
})
export default class BookingList extends Vue {
  @PropSync('calendarSelectedDate', { type: String }) syncCalendarSelectedDate!: string | '';
  bookingDate = ""; // new Date().toISOString().substr(0, 10);
  bookingDateMenu = false;
  bookings: BookingInfoDto[] = [];

  private selectedBookingNo: string | null = null;
  private selectedBooking: BookingDetailsDto | null = null;
  private shouldShowBookingActionDialog = false;

  private visitInfo: StoreVisitInfoDto | null = null;
  private shouldShowCancelVisitDialog = false;

  private enableDetailSearch = false;

  private hasNoBooking = false;

  errorMessage: string | null = null;
  showErrorMessage = false;
  errorMessageType = SnackbarMessageType.Error;

  private readonly keywordTypes = [
    { text: "전화번호 끝 4자리", value: BookingListKeywordTypeEnum.PhoneNumber4 },
    { text: "전화번호", value: BookingListKeywordTypeEnum.PhoneNumber },
    { text: "예약번호", value: BookingListKeywordTypeEnum.BookingNo },
  ];

  //private keywordType = BookingListKeywordTypeEnum.NoKeyword;
  private keywordType = BookingListKeywordTypeEnum.PhoneNumber4;
  private keyword = '';

  private keywordErrorMessage: string | null = null;

  private placeHolder = '전화번호 끝 4자리를 입력해주세요.';

  mounted(): void {
    let today = new Date();
    today.setTime(today.getTime() + today.getTimezoneOffset() * 60 * 1000);
    if (this.syncCalendarSelectedDate) {
      this.bookingDate = this.syncCalendarSelectedDate
      this.enableDetailSearch = true;
    } else {
      this.bookingDate = dateUtils.formatToday();
    }


    this.searchBookings();
  }

  get bookingActionDialog(): Vue.Component | null {
    return this.shouldShowBookingActionDialog ? BookingActionDialog : null;
  }

  get cancelVisitDialog(): Vue.Component | null {
    return this.shouldShowCancelVisitDialog ? CancelVisitDialog : null;
  }

  get datePicker(): Vue.Component | null {
    return this.bookingDateMenu ? DatePicker : null;
  }

  textFieldPlaceHolders(): void {
    switch (this.keywordType) {
      case BookingListKeywordTypeEnum.PhoneNumber4: this.placeHolder = '전화번호 끝 4자리를 입력해주세요.'; break;
      case BookingListKeywordTypeEnum.PhoneNumber: this.placeHolder = '전화번호를 입력해주세요.'; break;
      case BookingListKeywordTypeEnum.BookingNo: this.placeHolder = '예약번호를 입력해주세요.'; break;
    }
  }

  private toNextDay(): void {
    let theDay = new Date(this.bookingDate);
    theDay.setDate(theDay.getDate() + 1);
    // this.bookingDate = theDay.toISOString().substr(0, 10);
    this.bookingDate = dateUtils.toDateString(theDay);
  }

  private toPrevDay(): void {
    let theDay = new Date(this.bookingDate);
    theDay.setDate(theDay.getDate() - 1);
    // this.bookingDate = theDay.toISOString().substr(0, 10);
    this.bookingDate = dateUtils.toDateString(theDay);
  }

  private swipe(forward: boolean): void {
    if (forward) {
      this.toNextDay();
    } else {
      this.toPrevDay();
    }
  }

  selectedDate(date) {
    let theDay = new Date(date);
    this.bookingDate = dateUtils.toDateString(theDay);
  }

  @Watch("bookingDate")
  private onBookingDateUpdated(): void {
    this.searchBookings();
  }

  private async searchBookings(): Promise<void> {
    this.hasNoBooking = false;

    try {
      const response = await bookingApi.searchBookings(BookingDateTypeEnum.VisitDate, this.bookingDate, this.bookingDate,
        this.keywordType, this.keyword);

      let bookings: BookingInfoDto[] = response.data ?? [];
      this.bookings = bookings.filter(booking => !bookingUtils.isBookingRequest(booking.bookingStatus));

      if (this.bookings.length === 0) {
        this.hasNoBooking = true;
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }

    // bookingApi.searchBookings(BookingDateTypeEnum.VisitDate, this.bookingDate, this.bookingDate,
    //     this.keywordType, this.keyword)
    //     .then(apiResponse => {
    //       let bookings:BookingInfoDto[] = apiResponse.data ?? [];
    //       this.bookings = bookings.filter(booking => !bookingUtils.isBookingRequest(booking.bookingStatus));
    //
    //       if (this.bookings.length === 0) {
    //         this.hasNoBooking = true;
    //       }
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Error);
    //     })
  }

  private async refreshSelectedBooking(): Promise<void> {
    if (!this.selectedBookingNo) {
      return;
    }

    try {
      const response = await bookingApi.searchBookings(BookingDateTypeEnum.VisitDate, this.bookingDate, this.bookingDate,
        BookingListKeywordTypeEnum.BookingNo, this.selectedBookingNo);

      let responseBookings: BookingInfoDto[] = response.data ?? [];
      if (responseBookings.length > 0) {
        const bookingIndex = this.bookings.findIndex(booking => booking.bookingNo === this.selectedBookingNo);
        if (bookingIndex >= 0) {
          this.bookings[bookingIndex] = responseBookings[0];
          this.$forceUpdate();
        }
      }
    } catch (e) {
      const error = e as Error;
      console.log(error.message);
    }

    // bookingApi.searchBookings(BookingDateTypeEnum.VisitDate, this.bookingDate, this.bookingDate,
    //     BookingListKeywordTypeEnum.BookingNo, this.selectedBooking.bookingNo)
    //     .then(apiResponse => {
    //       let responseBookings:BookingInfoDto[] = apiResponse.data ?? [];
    //       if (responseBookings.length > 0) {
    //         const bookingIndex = this.bookings.findIndex(booking => booking.bookingNo === this.selectedBooking?.bookingNo);
    //         if (bookingIndex >= 0) {
    //           this.bookings[bookingIndex] = responseBookings[0];
    //           this.$forceUpdate();
    //         }
    //       }
    //     })
    //     .catch(errorMessage => {
    //       console.log(errorMessage);
    //     })
  }

  private onClickBooking(booking: BookingCommonDto): void {
    this.selectedBookingNo = booking.bookingNo;
    this.selectedBooking = null;

    if (bookingUtils.isVisited(booking.bookingStatus)) {
      this.showCancelVisitDialog(booking);
    } else {
      this.showBookingActionDialog(booking);
    }
  }

  private async showBookingActionDialog(booking: BookingCommonDto): Promise<void> {
    try {
      const response = await bookingApi.queryBookingDetails(booking.bookingNo);

      this.selectedBooking = response.data?.bookingDetails ?? null;
      this.shouldShowBookingActionDialog = true;

      if (this.selectedBooking) {
        this.selectedBooking.orderItems.forEach(orderItem => orderItem.checked = true);
      }
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Warning);
    }

    // bookingApi.queryBookingDetails(booking.bookingNo)
    //     .then(apiResponse => {
    //       this.selectedBooking = apiResponse.data?.bookingDetails ?? null;
    //       this.shouldShowBookingActionDialog = true;
    //
    //       if (this.selectedBooking) {
    //         this.selectedBooking.orderItems.forEach(orderItem => orderItem.checked = true);
    //       }
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Warning);
    //     })
  }

  private async showCancelVisitDialog(booking: BookingCommonDto): Promise<void> {
    try {
      const response = await bookingApi.queryVisitInfo(booking.bookingNo);

      this.visitInfo = response.data ?? null;
      this.shouldShowCancelVisitDialog = true;
    } catch (e) {
      const error = e as Error;
      this.toastErrorMessage(error.message, SnackbarMessageType.Warning);
    }

    // bookingApi.queryVisitInfo(booking.bookingNo)
    //     .then(apiResponse => {
    //       this.visitInfo = apiResponse.data ?? null;
    //       this.shouldShowCancelVisitDialog = true;
    //     })
    //     .catch(errorMessage => {
    //       this.toastErrorMessage(errorMessage, SnackbarMessageType.Warning);
    //     })
  }

  private bookingCancelled(): void {
    this.refreshSelectedBooking();
    this.toastErrorMessage("예약이 취소되었습니다.", SnackbarMessageType.Info)
  }

  private hadNoShow(): void {
    this.refreshSelectedBooking();
    this.toastErrorMessage("노쇼 처리하였습니다.", SnackbarMessageType.Info)
  }

  private visitCompleted(): void {
    this.refreshSelectedBooking();
    this.toastErrorMessage("방문처리 하였습니다.", SnackbarMessageType.Info)
  }

  private visitCancelled(): void {
    this.refreshSelectedBooking();
    this.toastErrorMessage("방문이 취소되었습니다.", SnackbarMessageType.Info)
  }

  private noShowCancelled(): void {
    this.refreshSelectedBooking();
    this.toastErrorMessage("노쇼처리가 취소되었습니다.", SnackbarMessageType.Info)
  }

  private toastErrorMessage(message: string, messageType: SnackbarMessageType) {
    this.errorMessage = message;
    this.errorMessageType = messageType;
    this.showErrorMessage = true;
  }


}
