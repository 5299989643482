
import { Component, Prop, PropSync, Emit, Vue } from "vue-property-decorator";


@Component
export default class DatePicker extends Vue {
  @PropSync('selectDate', { type: String }) syncDate!: string;
  @PropSync('showDatePicker', { type: Boolean }) syncShowPicker!: boolean;

  setDay(date: Date): string {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    let week = new Date(date).getDay()
    return daysOfWeek[week];
  }

  @Emit()
  selectedDate(): void {
    this.syncShowPicker = false;
    return;
  }
}

