
import { bookingApi } from "@/api/service/bookingApi";
import { NoticeAdminBoardDetailDto } from "@/api/service/dto/order/boardListDto";
import { siteStore } from "@/store";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component({})
export default class NoticeInfo extends Vue {
  private noticeDetail: NoticeAdminBoardDetailDto | null = null;
  mounted(): void {
    this.setNoticeDetail()
  }

  private async setNoticeDetail() {
    const response = await bookingApi.noticeAdminBoardDetail(Number(this.$route.params.notice_no))
    this.noticeDetail = response.data
  }
  setDateFormat(registeredDate: string): string {
    const date = new Date(registeredDate)
    if (date.getMonth() + 1 < 10) {
      if (date.getDate() < 10) {
        return `${date.getFullYear()}-0${date.getMonth() + 1}-0${date.getDate()}`
      }
      else {
        return `${date.getFullYear()}-0${date.getMonth() + 1}-${date.getDate()}`
      }
    } else {
      if (date.getDate() < 10) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-0${date.getDate()}`
      }
      else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    }
  }

  private onClickNoticeList() {
    this.$router.push('/' + siteStore.siteName + '/notice/notice-list')
  }
}
