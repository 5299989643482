import {
  BookingListKeywordTypeEnum,
  BookingReserveSummaryDto,
  reservationRequestDto,
  dashboardDto,
  reservationStatusDto,
} from "./dto/order/bookingListDto";
import {
  NoticeAdminBoardDetailDto,
  NoticeAdminBoardPageDto,
} from "./dto/order/boardListDto";
import { ApiResponse, httpClient } from "@/api/httpClient";
import { apiConstants } from "@/api/apiConstants";
import { BookingRequestInfoDto } from "@/api/service/dto/order/bookingRequestListDto";
import {
  BookingDateTypeEnum,
  BookingInfoDto,
  BookingListRequestDto,
} from "@/api/service/dto/order/bookingListDto";
import {
  BookingDetailsRequestDto,
  BookingDetailsResponseDto,
} from "@/api/service/dto/order/bookingDetailsDto";
import { StoreVisitInfoDto } from "@/api/service/dto/order/storeVisitInfoDto";
import { NoShowRefundAmountDto } from "@/api/service/dto/order/noShowRefundAmountDto";
import { BookingCancelRefundAmountDto } from "@/api/service/dto/order/bookingCancelRefundAmountDto";
import { BoardListKeywordTypeEnum } from "./dto/order/boardListDto";

// export enum BookingDateType {
//     RequestTime = "RequestTime", VisitDate = "VisitDate"
// }

// export enum BookingKeywordType {
//     NoKeyword = "NoKeyword", BookingNo = "BookingNo", PhoneNumber = "PhoneNumber", PhoneNumber4 = "PhoneNumber4"
// }

class BookingApi {
  searchBookingRequests(): Promise<ApiResponse<BookingRequestInfoDto[]>> {
    return httpClient.httpGet(apiConstants.order.bookingRequestList);
  }

  searchBookings(
    dateType: BookingDateTypeEnum,
    dateFrom: string,
    dateTo: string,
    keywordType: BookingListKeywordTypeEnum,
    keyword: string
  ): Promise<ApiResponse<BookingInfoDto[]>> {
    const request: BookingListRequestDto = {
      dateType,
      dateFrom,
      dateTo,
      keywordType,
      keyword,
    };

    return httpClient.httpPost(apiConstants.order.bookingList, request);
  }

  queryBookingDetails(
    bookingNo: string
  ): Promise<ApiResponse<BookingDetailsResponseDto>> {
    const request: BookingDetailsRequestDto = {
      bookingNo,
    };

    return httpClient.httpPost(apiConstants.order.bookingDetail, request);
  }

  rejectBookingRequest(bookingNo: string): Promise<ApiResponse<void>> {
    return httpClient.httpPost(apiConstants.order.rejectBookingRequest, {
      bookingNo: bookingNo,
    });
  }

  acceptBookingRequest(bookingNo: string): Promise<ApiResponse<void>> {
    return httpClient.httpPost(apiConstants.order.approveBookingRequest, {
      bookingNos: [bookingNo],
    });
  }

  visitStore(
    bookingNo: string,
    orderItems: string[],
    noPenalty
  ): Promise<ApiResponse<void>> {
    return httpClient.httpPost(apiConstants.order.visitStore, {
      bookingNo: bookingNo,
      orderItems: orderItems,
      noPenalty,
    });
  }

  noShow(
    bookingNo: string,
    noPenalty: boolean,
    type: any,
    reasonType: any
  ): Promise<ApiResponse<void>> {
    return httpClient.httpPost(apiConstants.order.noShow, {
      bookingNo: bookingNo,
      noPenalty,
      type,
      reasonType,
    });
  }

  cancelBooking(
    bookingNo: string,
    noPenalty: boolean,
    type: any,
    reasonType: any
  ): Promise<ApiResponse<void>> {
    return httpClient.httpPost(apiConstants.order.cancelBooking, {
      bookingNo: bookingNo,
      noPenalty,
      type,
      reasonType,
    });
  }

  queryVisitInfo(bookingNo: string): Promise<ApiResponse<StoreVisitInfoDto>> {
    return httpClient.httpPost(apiConstants.order.storeVisitInfo, {
      bookingNo: bookingNo,
    });
  }

  cancelVisit(
    bookingNo: string,
    transactionNo: string
  ): Promise<ApiResponse<void>> {
    return httpClient.httpPost(apiConstants.order.cancelVisitStore, {
      bookingNo: bookingNo,
      transactionNo: transactionNo,
    });
  }

  bookingCancelRefundAmount(
    bookingNo: string
  ): Promise<ApiResponse<BookingCancelRefundAmountDto>> {
    return httpClient.httpPost(apiConstants.order.bookingCancelRefundAmount, {
      bookingNo: bookingNo,
    });
  }

  noShowRefundAmount(
    bookingNo: string
  ): Promise<ApiResponse<NoShowRefundAmountDto>> {
    return httpClient.httpPost(apiConstants.order.noShowRefundAmount, {
      bookingNo: bookingNo,
    });
  }

  bookingReserveSummary(
    dateType: string,
    dateFrom: string,
    dateTo: string,
    storeCode: string,
    keywordType: BookingListKeywordTypeEnum
  ): Promise<ApiResponse<BookingReserveSummaryDto[]>> {
    const request: reservationRequestDto = {
      dateType,
      dateFrom,
      dateTo,
      storeCode,
      keywordType,
    };

    return httpClient.httpPost(
      apiConstants.order.bookingReserveSummary,
      request
    );
  }

  dashboard(): Promise<ApiResponse<dashboardDto>> {
    return httpClient.httpGet(apiConstants.order.dashboard);
  }

  dashboardBookingReserveSummary(): Promise<ApiResponse<reservationStatusDto>> {
    return httpClient.httpGet(
      apiConstants.order.dashboardBookingReserveSummary
    );
  }

  noticeAdminBoardPage(
    keywordType: BoardListKeywordTypeEnum,
    keyword: string | null,
    publishedOnly: boolean | null,
    pageNumber: number,
    pageSize: number
  ): Promise<ApiResponse<NoticeAdminBoardPageDto>> {
    const request = {
      keywordType,
      keyword,
      publishedOnly,
      pageNumber,
      pageSize,
    };
    return httpClient.httpPost(
      apiConstants.board.noticeAdminBoardPage,
      request
    );
  }

  noticeAdminBoardDetail(
    noticeNo: number
  ): Promise<ApiResponse<NoticeAdminBoardDetailDto>> {
    const request = {
      noticeNo,
    };

    return httpClient.httpPost(
      apiConstants.board.noticeAdminBoardDetail,
      request
    );
  }

  // cancelNoShow(bookingNo: string, transactionNo:string): Promise<ApiResponse> {
  //     return httpClient.httpPost(apiConstants.order.cancelNoShow, {
  //         bookingNo: bookingNo,
  //         transactionNo: transactionNo
  //     })
  // }
}

export const bookingApi = new BookingApi();
