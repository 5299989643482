
import { Component, Emit, Model, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { numberUtils } from "@/lib/numberUtils";

@Component
export default class MoneyInput extends Vue {
  @Prop() readonly maxAmount!: string;
  @Prop() readonly towRounded!: boolean;
  @PropSync('errorMessage', { type: String }) syncErrorMessage!: string | null;

  readonly initAmount = "";
  currencyValue: string | null = null;

  private addAll(): void {
    this.currencyValue = this.maxAmount ? this.maxAmount : null;

    this.updateInputText();
    this.clearErrorMessage();
  }

  private add10000(): void {
    this.addAmount(10000);
  }

  private add1000(): void {
    this.addAmount(1000);
  }

  private add100(): void {
    this.addAmount(100);
  }

  private addAmount(amount: number) {
    if (this.currencyValue) {
      this.currencyValue = (parseInt(this.currencyValue) + amount).toString();
    } else {
      this.currencyValue = amount.toString();
    }

    this.updateInputText();
    this.clearErrorMessage();
  }

  filterCurrencyCharsOnly(value: string): void {
    this.currencyValue = value ? value.replace(/\D/g, '') : null;

    this.updateInputText();
  }

  private updateInputText(): void {
    const formattedCurrencyValue = numberUtils.formatNumber(this.currencyValue);

    const field: any = this.$refs.formRef as any;
    field.lazyValue = formattedCurrencyValue;

    this.amountChanged();
  }

  @Emit()
  amountChanged(): string | null {
    return this.currencyValue;
  }

  private clearErrorMessage(): void {
    this.syncErrorMessage = null;
  }
}
