import { GoodsTypeEnum } from "@/api/service/type/goodsTypeEnum";
import { AgeGroupEnum } from "@/api/service/type/ageGroupEnum";
import { BookingCommonDto } from "@/api/service/dto/order/bookingCommonDto";
import { BookingTypeEnum } from "../../type/bookingTypeEnum";
import { BookingStatusEnum } from "../../type/bookingStatusEnum";

export enum BookingDateTypeEnum {
  RequestTime = "RequestTime",
  VisitDate = "VisitDate",
}

export enum BookingListKeywordTypeEnum {
  NoKeyword = "NoKeyword",
  BookingNo = "BookingNo",
  PhoneNumber = "PhoneNumber",
  PhoneNumber4 = "PhoneNumber4",
}

export interface BookingListRequestDto {
  dateType: BookingDateTypeEnum;
  dateFrom: string;
  dateTo: string;
  keywordType: BookingListKeywordTypeEnum;
  keyword: string;
}

export interface BookingInfoItemDto {
  goodsType: GoodsTypeEnum;
  goodsId: number;
  goodsName: string;
  optionId: number;
  optionName: string;
  ageGroup: AgeGroupEnum;
  headcount: number;
}

export interface BookingInfoDto extends BookingCommonDto {
  cancellationFeeRate: string; // % 단위
  orderItems: BookingInfoItemDto[];
}

export interface reservationRequestDto {
  dateType: string;
  dateFrom: string;
  dateTo: string;
  storeCode: string;
  keywordType: BookingListKeywordTypeEnum;
}

export interface reservationTimeInfos {
  reservationTime: string;
  headCounts: number;
}
export interface BookingReserveSummaryDto {
  reservationDate: string;
  headCounts: number;
  reservationTimeInfos: reservationTimeInfos[];
}

export interface GoodsTypesDto {
  goodsType: GoodsTypeEnum;
  listPriceTotal: string;
  originalSalesAmountTotal: string;
  discountAmountTotal: string;
  salesAmountTotal: string;
  salesDeliveryAmountTotal: string;
  salesCommissionTotal: string;
  penaltyTotal: string;
  penaltyDeliveryAmountTotal: string;
  penaltyCommissionTotal: string;
  deliveryAmountTotal: string;
  commissionTotal: string;
  currencyCode: string;
}

export interface DashboardDateInfoDto {
  salesDate: string;
  goodsTypes: GoodsTypesDto[];
}
export interface dashboardDto {
  date: DashboardDateInfoDto;
  totalCommissionAmount: string;
  totalDeleveryAmount: string;
}

export interface bookingSummaryListDto {
  bookingNo: string;
  bookingType: BookingTypeEnum;
  depositBooking: boolean;
  depositAmountTotal: string;
  visitorName: string;
  visitorPhone: string;
  visitorEmail: string;
  visitorRequests: string;
  brandCode: string;
  brandName: string;
  storeCode: string;
  storeName: string;
  timeSaleRate: string;
  siteName: string;
  bookingRequestDate: string;
  bookingCancelledDate: string;
  reservationDate: string;
  visitDate: string;
  itemCount: string;
  adultHeadcount: string;
  childHeadcount: string;
  babyHeadcount: string;
  bookingStatus: BookingStatusEnum;
  noShow: boolean;
  memo: string;
  b2bChannelCode: string;
  b2bBookingNo: string;
  orderNo: string;
}
export interface reservationStatusDto {
  bookingTotalCount: number;
  bookingRequestTotalCount: number;
  bookingSummaryList: bookingSummaryListDto[];
}
