import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { siteStore } from "@/store";

enum SiteKey {
  siteName = "siteName",
  brandName = "brandName",
  logoUrl = "logoUrl",
  title = "title",
}

@Module({ namespaced: true, name: "site" })
class Site extends VuexModule {
  private _siteName: string | null = null;
  private _brandName: string | null = null;
  private _logoUrl: string | null = null;

  private _title: string | null = null;

  get siteName(): string | null {
    if (this._siteName) {
      return this._siteName;
    } else {
      const siteName = localStorage.getItem(SiteKey.siteName);
      if (siteName) {
        siteStore.updateSiteName(siteName);
      }
      return siteName;
    }
  }

  get brandName(): string | null {
    if (this._brandName) {
      return this._brandName;
    } else {
      const brandName = localStorage.getItem(SiteKey.brandName);
      if (brandName) {
        siteStore.updateBrandName(brandName);
      }
      return brandName;
    }
  }

  get logoUrl(): string | null {
    if (this._logoUrl) {
      return this._logoUrl;
    } else {
      const logoUrl = localStorage.getItem(SiteKey.logoUrl);
      if (logoUrl) {
        siteStore.updateLogoUrl(logoUrl);
      }
      return logoUrl;
    }
  }

  get title(): string | null {
    if (this._title) {
      return this._title;
    } else {
      const title = localStorage.getItem(SiteKey.title);
      if (title) {
        siteStore.updateTitle(title);
      }
      return title;
    }
  }

  @Mutation
  public setSiteName(siteName: string | null): void {
    this._siteName = siteName;
    if (siteName) {
      localStorage.setItem(SiteKey.siteName, siteName);
    } else {
      localStorage.removeItem(SiteKey.siteName);
    }
  }

  @Mutation
  public setBrandName(brandName: string | null): void {
    this._brandName = brandName;
    if (brandName) {
      localStorage.setItem(SiteKey.brandName, brandName);
    } else {
      localStorage.removeItem(SiteKey.brandName);
    }
  }

  @Mutation
  public setLogoUrl(logoUrl: string | null): void {
    this._logoUrl = logoUrl;
    if (logoUrl) {
      localStorage.setItem(SiteKey.logoUrl, logoUrl);
    } else {
      localStorage.removeItem(SiteKey.logoUrl);
    }
  }

  @Mutation
  public setTitle(title: string | null): void {
    this._title = title;
    if (title) {
      localStorage.setItem(SiteKey.title, title);
    } else {
      localStorage.removeItem(SiteKey.title);
    }
  }

  @Action
  public updateSiteName(siteName: string | null): void {
    this.context.commit("setSiteName", siteName);
  }

  @Action
  public updateBrandName(brandName: string | null): void {
    this.context.commit("setBrandName", brandName);
  }

  @Action
  public updateLogoUrl(logoUrl: string | null): void {
    this.context.commit("setLogoUrl", logoUrl);
  }

  @Action
  public updateTitle(title: string | null): void {
    this.context.commit("setTitle", title);
  }

  @Action
  public clearAll(): void {
    this.updateSiteName(null);
    this.updateBrandName(null);
    this.updateLogoUrl(null);
    this.updateTitle(null);
  }
}

export default Site;
