export enum GoodsTypeEnum {
  MobileCoupon = "MobileCoupon",
  BookingCoupon = "BookingCoupon",
  Booking = "Booking",
  StoreBooking = "StoreBooking",
  MoneyCoupon = "MoneyCoupon",
  CashCoupon = "CashCoupon",
  Combined = "Combined",
  PointCoupon = "PointCoupon",
}
