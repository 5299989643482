
import { Component, Vue } from 'vue-property-decorator';
import TopToolbar from './components/TopToolbar.vue';
import BottomNav from './components/BottomNav.vue';
import ManageUserDialog from "@/views/user/ManageUserDialog.vue";
import QrScanListDialog from "@/views/qrscan/QrScanListDialog.vue";

@Component({
  components: {
    ManageUserDialog,
    TopToolbar,
    BottomNav,
    QrScanListDialog
  }
})
export default class extends Vue {
  // timerId: number | null = null;
  //
  // mounted(): void {
  //   console.log('mounted');
  //   this.timerId = setInterval(this.refreshToken, 600000);
  // }
  //
  // private refreshToken(): void {
  //   httpClient.refreshTokenAct()
  //       .then(token => {
  //         console.log("token refreshed");
  //       })
  //       .catch(message => {
  //         console.log(message);
  //       });
  // }
}
