
import { bookingApi } from "@/api/service/bookingApi";
import { BoardListKeywordTypeEnum, NoticeAdminBoardPageDto } from "@/api/service/dto/order/boardListDto";
import { siteStore } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NoticeList extends Vue {
  private noticeAdminBoard: NoticeAdminBoardPageDto | null = null;

  private keywordType = BoardListKeywordTypeEnum.NoKeyword

  pageIndex = 1
  pageCount = 0

  headers = [
    { align: 'center', value: 'noticeNo', text: '번호', width: '20%' },
    { align: 'start', value: 'title', text: '제목' },
    { align: 'center', value: 'registeredDate', text: '작성일', width: '30%' },
  ]

  searchTypes = ['제목', '작성자ID'
    /* { value: BoardListKeywordTypeEnum.TitleContaining, label: '제목' },
    { value: BoardListKeywordTypeEnum.RegisteredBy, label: '작성자ID' }, */
  ]

  searchType = '제목'
  searchKeyword = ''

  private shouldShowNoticeDetail = false



  mounted() {
    this.setNoticeAdminBoard(null, null, 0)
  }

  onClickNotice(data): void {
    this.$router.push('/' + siteStore.siteName + '/notice/notice-list/' + data.noticeNo)
  }

  changePage(e) {
    this.pageIndex = e
    if (this.searchKeyword) {
      this.setNoticeAdminBoard(this.searchKeyword, null, this.pageIndex - 1)
    } else {
      this.setNoticeAdminBoard(null, null, this.pageIndex - 1)
    }
  }

  setDateFormat(registeredDate: string): string {
    const date = new Date(registeredDate)
    if (date.getMonth() + 1 < 10) {
      if (date.getDate() < 10) {
        return `${date.getFullYear()}-0${date.getMonth() + 1}-0${date.getDate()}`
      }
      else {
        return `${date.getFullYear()}-0${date.getMonth() + 1}-${date.getDate()}`
      }
    } else {
      if (date.getDate() < 10) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-0${date.getDate()}`
      }
      else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    }
  }

  onClickSearchNotice(): void {
    switch (this.searchType) {
      case '제목': this.keywordType = BoardListKeywordTypeEnum.TitleContaining;
        break;
      case '작성자ID': this.keywordType = BoardListKeywordTypeEnum.RegisteredBy;
        break;
      default: this.keywordType = BoardListKeywordTypeEnum.NoKeyword;
        break;
    }
    this.setNoticeAdminBoard(this.searchKeyword, null, this.pageIndex - 1)
  }

  private async setNoticeAdminBoard(keyword: string | null, publishedOnly: boolean | null, pageNumber: number): Promise<void> {
    try {
      const response = await bookingApi.noticeAdminBoardPage(this.keywordType, keyword, publishedOnly, pageNumber, 100)
      this.noticeAdminBoard = response.data
    } catch (e) {
      const error = e as Error;
      console.log(error)
    }
  }
}
