import { ApiResponse, httpClient } from "@/api/httpClient";
import { apiConstants } from "@/api/apiConstants";
import { BrandSiteListDto } from "@/api/service/dto/brand/brandSiteListDto";
import { BrandInfoDto } from "@/api/service/dto/brand/brandInfoDto";
import { StoreInfoDto } from "@/api/service/dto/brand/storeInfoDto";
import {
  bookingStatusMonthlyDto,
  bookingStatusWeeklyDto,
} from "./dto/brand/bookingCalendarDto";
import { totalizeDto } from "./dto/brand/settlementsDto";

class BrandApi {
  queryBrandList(domain: string): Promise<ApiResponse<BrandSiteListDto>> {
    return httpClient.httpGet(
      apiConstants.brand.brandSiteList,
      "domain=" + domain
    );
  }

  queryBrandInfo(): Promise<ApiResponse<BrandInfoDto>> {
    return httpClient.httpGet(apiConstants.brand.brandSiteInfo);
  }

  queryStoreInfo(): Promise<ApiResponse<StoreInfoDto>> {
    return httpClient.httpGet(apiConstants.shop.basicInfo);
  }

  bookingStatusMonthly(
    yearMonth: string
  ): Promise<ApiResponse<bookingStatusMonthlyDto>> {
    return httpClient.httpGet(
      apiConstants.shop.bookingStatusMonthly,
      "yearMonth=" + yearMonth
    );
  }

  bookingStatusWeekly(
    includedDate: string
  ): Promise<ApiResponse<bookingStatusWeeklyDto>> {
    return httpClient.httpGet(
      apiConstants.shop.bookingStatusWeekly,
      "includedDate=" + includedDate
    );
  }

  bookingStop(date: string) {
    const request = { date };
    return httpClient.httpPost(apiConstants.shop.bookingDateStop, request);
  }

  bookingResume(date: string) {
    const request = { date };
    return httpClient.httpPost(apiConstants.shop.bookingDateResume, request);
  }

  bookingSlotStop(date: string, hour: number, ahalf: boolean) {
    const request = {
      date,
      hour,
      ahalf,
    };
    return httpClient.httpPost(apiConstants.shop.bookingTimeSlotStop, request);
  }
  bookingSlotResume(date: string, hour: number, ahalf: boolean) {
    const request = {
      date,
      hour,
      ahalf,
    };
    return httpClient.httpPost(
      apiConstants.shop.bookingTimeSlotResume,
      request
    );
  }

  settlementsTotalize(
    salesFrom: string,
    salesTo: string
  ): Promise<ApiResponse<totalizeDto>> {
    const request = {
      salesFrom,
      salesTo,
    };

    return httpClient.httpPost(apiConstants.sales.settlementsStr, request);
  }
}

export const brandApi = new BrandApi();
