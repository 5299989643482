
import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";
import { dashboardDto } from "@/api/service/dto/order/bookingListDto";


@Component({})
export default class SalesDetailDialog extends Vue {
  @Prop() readonly salesDetail!: dashboardDto;
  @PropSync('showDialog', { type: Boolean }) syncShowDialog!: boolean;

  get classAttrKey(): string {
    return "attrKey primary-black text-start pl-4  py-1 pr-1 ma-0 ";
  }
  get classAttrValue(): string {
    return "attrValue  primary-black  pr-4 pl-3 py-1 ma-0";
  }
  get attrKeyCols(): string {
    return "3";
  }
  get attrValueCols(): string {
    return "9";
  }

  private calcTotalPrice(type: string) {
    const typesFilterArray = this.salesDetail.date.goodsTypes.filter((item) => item.goodsType == type)
    let typesFilterTotal = 0
    for (let i = 0; i < typesFilterArray.length; i++) {
      typesFilterTotal += Number(typesFilterArray[i].deliveryAmountTotal)
    }

    return `${typesFilterTotal}`
  }

  private setDateFormat(date: string): string {
    let salesDate = new Date(date)
    let setDate = `${salesDate.getMonth() + 1}월 ${salesDate.getDate()}일 매출 내역`

    return setDate
  }

  private setPriceFormat(price: string): string {
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'
  }

  private closeDialog(): void {
    this.syncShowDialog = false;
  }
}
