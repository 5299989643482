
import { brandApi } from "@/api/service/brandApi";
import { totalizeDto, totalizeGoodsTypeDto } from "@/api/service/dto/brand/settlementsDto";
import DatePicker from "@/components/common/DatePicker.vue";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import { dateUtils } from "@/lib/dateUtils";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component({ components: { MessageSnackbar } })
export default class SalesStatus extends Vue {
  today = new Date()

  selectDateFromMenu = false
  selectDateToMenu = false

  salesFrom = dateUtils.toDateString(new Date(this.today.getFullYear(), this.today.getMonth(), 1))
  salesTo = dateUtils.toDateString(this.today);

  errorMessage: string | null = null;
  showErrorMessage = false;
  errorMessageType = SnackbarMessageType.Error;

  get salesDateFromPicker(): Vue.Component | null {
    return this.selectDateFromMenu ? DatePicker : null;
  }

  get salesDateToPicker(): Vue.Component | null {
    return this.selectDateToMenu ? DatePicker : null;
  }

  exampleData = [{
    salesDate: '2024-07-20',
    goodsTypes: [{
      deliveryAmountTotal: '0',
      commissionTotal: '0',
    }]

  },
  {

    salesDate: '2024-07-21',
    goodsTypes: [
      {
        deliveryAmountTotal: '0',
        commissionTotal: '6901',
      }, {
        deliveryAmountTotal: '0',
        commissionTotal: '6901',
      }, {
        deliveryAmountTotal: '0',
        commissionTotal: '6901',
      }, {
        deliveryAmountTotal: '0',
        commissionTotal: '6901',
      }, {
        deliveryAmountTotal: '0',
        commissionTotal: '6901',
      },
    ]

  },
  {

    salesDate: '2024-08-01',
    goodsTypes: [{
      deliveryAmountTotal: '6915',
      commissionTotal: '0',
    },
    {
      deliveryAmountTotal: '6915',
      commissionTotal: '0',
    }]

  }

  ]

  private totalResponse: totalizeDto | null = null;


  setCellCalc(data: any, key: string): string {
    let total = 0
    if (key == 'deliveryAmountTotal') {
      for (let i = 0; i < data.length; i++) {
        total += Number(data[i].deliveryAmountTotal)
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        total += Number(data[i].commissionTotal)
      }
    }

    return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  }

  setTotalCalc(key: string): string {
    let total = 0
    if (!this.totalResponse) {
      return '0';
    }

    if (key == 'deliveryAmountTotal') {
      for (let i = 0; i < this.totalResponse.dates.length; i++) {
        for (let j = 0; j < this.totalResponse.dates[i].goodsTypes.length; j++) {
          total += Number(this.totalResponse.dates[i].goodsTypes[j].deliveryAmountTotal)
        }
      }
    } else {
      for (let i = 0; i < this.totalResponse.dates.length; i++) {
        for (let j = 0; j < this.totalResponse.dates[i].goodsTypes.length; j++) {
          total += Number(this.totalResponse.dates[i].goodsTypes[j].commissionTotal)
        }
      }
    }
    return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  mounted(): void {
    this.setTotalize()
  }

  private async setTotalize(): Promise<void> {
    this.totalResponse = null
    try {
      const response = await brandApi.settlementsTotalize(dateUtils.toDateString(new Date(this.salesFrom)), dateUtils.toDateString(new Date(this.salesTo)))
      this.totalResponse = response.data ?? null;
    } catch (e) {
      const error = e as Error;

      console.log(error)
      this.toastErrorMessage(error.message, SnackbarMessageType.Error);
    }
  }

  selectedSalesFrom(date): void {
    console.log(date)
    this.salesFrom = date
  }

  selectedSalesTo(date): void {
    console.log(date)
    this.salesTo = date
  }

  private toastErrorMessage(message: string, messageType: SnackbarMessageType) {
    this.errorMessage = message;
    this.errorMessageType = messageType;
    this.showErrorMessage = true;
  }

}
