
import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";
import BookingInfo from "@/components/booking/BookingInfo.vue";
import { bookingApi } from "@/api/service/bookingApi";
import MessageSnackbar, { SnackbarMessageType } from "@/components/common/MessageSnackbar.vue";
import { bookingUtils } from "@/lib/bookingUtils";
import { BookingDetailsDto } from "@/api/service/dto/order/bookingDetailsDto";
import { numberUtils } from "@/lib/numberUtils";
import BookingList from "@/views/booking/visit/BookingList.vue";


@Component({
  components: { BookingInfo, MessageSnackbar }
})
export default class BookingListDialog extends Vue {
  @PropSync('selectedDate', { type: String }) syncSelectedDate!: string;
  @PropSync('showDialog', { type: Boolean }) syncShowDialog!: boolean;

  get bookingVisitDialog(): Vue.Component | null {
    return this.syncShowDialog ? BookingList : null;
  }

  private closeDialog(): void {
    this.syncShowDialog = false;
  }
}
