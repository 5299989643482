
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BottomNav extends Vue {
  activeItem = 'top';

  mounted() {
    switch (this.$route.name) {
      case 'use-coupon': this.activeItem = 'top'; break;
      case 'visit-store': this.activeItem = 'code'; break;
      case 'approve-booking': this.activeItem = 'favorites'; break;
    }
  }
}
