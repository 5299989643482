
import { Component, Prop, Vue, PropSync } from "vue-property-decorator";
import { couponUtils } from "@/lib/couponUtils";
import { CouponProps } from "@/components/coupon/type/CouponProps";
import { dateUtils } from "@/lib/dateUtils";
import { numberUtils } from "@/lib/numberUtils";
import { phoneUtils } from "@/lib/phoneUtils";

@Component
export default class CouponInfo extends Vue {
  @Prop() readonly coupon!: CouponProps;
  @Prop() readonly consuming!: boolean;
  @PropSync('multiple', { type: Boolean }) syncMultiple!: boolean;
  @PropSync('couponList', { type: Array }) syncCouponList!: CouponProps[] | null;


  today = dateUtils.formatToday();

  mounted() {
    console.log(this.coupon);
  }

  get classCouponAttrKey(): string {
    return "primary-black text-start pl-4  py-1 pr-1 ma-0 attrKey";
  }

  get classCouponAttrValue(): string {
    return " attrValue primary-black  pr-4 pl-3 py-1 ma-0";
  }

  get couponAttrKeyCols(): string {
    return "3";
  }

  get couponAttrValueCols(): string {
    return "9";
  }

  get couponNumber(): string {
    if (couponUtils.isBookingCoupon(this.coupon.goodsType)) {
      return this.coupon.itemNo + " (예약권)"
    } else {
      return this.coupon.itemNo;
    }
  }

  get couponStatus(): string {
    return couponUtils.couponStatusCaption(this.coupon);
  }

  get isAvailableCoupon(): boolean {
    return couponUtils.isAvailableCoupon(this.coupon.itemStatus);
  }

  get isMobileCoupon(): boolean {
    return couponUtils.isMobileCoupon(this.coupon.goodsType);
  }

  get isMoneyCoupon(): boolean {
    return couponUtils.isMoneyCoupon(this.coupon.goodsType);
  }

  get couponTypeCaption(): string {
    return couponUtils.goodsTypeCaption(this.coupon.goodsType);
  }

  get consumedDate(): string {
    return dateUtils.formatDate(this.coupon.consumedDate);
  }

  get usableAmount(): string {
    if (this.syncMultiple && this.syncCouponList) {
      let totalUsableAmount = 0
      for (let i = 0; i < this.syncCouponList.length; i++) {
        totalUsableAmount += Number(numberUtils.formatNumber(this.syncCouponList[i].usableAmount).replace(',', ''))
      }
      return totalUsableAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    else {
      return numberUtils.formatNumber(this.coupon.usableAmount);
    }
  }

  get receiverPhone(): string {
    return phoneUtils.formatPhoneNumber(this.coupon.receiverPhone);
  }
}
